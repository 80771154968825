/**
 * Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 *
 */
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { INPUT_FORMAT, DEFAULT_FORMAT } from '../../constants/timeFormats';

const DisplayTime = ({ time, format = DEFAULT_FORMAT, isUtc = false }) => {
  const parsedTime = isUtc
    ? moment.utc(time, INPUT_FORMAT)
    : moment.utc(time, INPUT_FORMAT).local();

  const formattedTime = parsedTime.format(format);
  const timestamp = parsedTime.fromNow();
  return <>{` ${formattedTime} (${timestamp})`}</>;
};

DisplayTime.propTypes = {
  isUtc: PropTypes.bool,
  time: PropTypes.string.isRequired,
  format: PropTypes.string,
};

export default DisplayTime;
