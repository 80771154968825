/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

export const getAll = (builder) =>
  builder.query({
    query: ({ endpoint }) => ({
      url: `external-api/${endpoint}`,
      method: 'GET',
    }),
    providesTags: ['external-apis'],
  });

export const get = (builder) =>
  builder.query({
    query: ({ id, endpoint }) => {
      return {
        url: `external-api/${endpoint}/${id}`,
        method: 'GET',
      };
    },
    invalidatesTags: ['external-api'],
  });

export const create = (builder) =>
  builder.mutation({
    query: ({ body, endpoint }) => {
      return {
        url: `external-api/${endpoint}`,
        method: 'POST',
        body: body,
      };
    },
    invalidatesTags: ['external-apis'],
  });

export const update = (builder) =>
  builder.mutation({
    query: ({ update, id, endpoint }) => {
      return {
        url: `external-api/${endpoint}/${id}`,
        method: 'PATCH',
        body: update,
      };
    },
    invalidatesTags: ['external-apis'],
  });

export const deleteMany = (builder) =>
  builder.mutation({
    query: ({ endpoint, ids }) => {
      return {
        url: `external-api/${endpoint}`,
        method: 'DELETE',
        body: { ids },
      };
    },
    invalidatesTags: ['external-apis'],
  });
