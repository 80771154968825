/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { Box, styled, Alert, LinearProgress } from '@mui/material';

import colors from '../../../themes/colors';
import { DataGrid } from '@mui/x-data-grid';

export const PageHeader = styled(Box)(({ theme }) => ({
  fontSize: 24,
}));

export const FormContainer = styled(Box)(({ theme }) => ({
  margin: '20px 0',
  fontSize: 16,
  overflow: 'hidden',

  [theme.breakpoints.down('sm')]: {
    width: '100%',
    padding: '0 10px',
  },
}));

export const ToogleButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: '20px 0',
}));

export const ToogleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '20%',
  marginRight: 25,
}));

export const ErrorText = styled(Box)(({ theme }) => ({
  color: colors.red.R400,
  margin: '5px 0',
}));

export const InputsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '20px 0',
  gap: 20,
}));

export const DescriptionStyled = styled(Box)({
  marginTop: 10,
});

export const DataSourceBox = styled(Box)({
  margin: '10px 0',
  fontSize: 16,
  color: colors.neutralLight.N70,
});

export const DataSourceName = styled(Box)({
  fontSize: 24,
  fontWeight: 'initial',
  color: colors.neutralLight.N70,
  marginRight: 10,
});

export const DataSourceType = styled(Box)({
  fontSize: 12,
  margin: '5px 0',
  fontWeight: 'bold',
  color: colors.neutralLight.N500,
});

export const AlertStyled = styled(Alert)({
  margin: '10px 0',
  transition: 'margin 0.3s',
});

export const NoDataTitle = styled(Box)({
  textAlign: 'center',
  marginTop: '20%',
});

export const TableStyled = styled(DataGrid)(({ theme }) => ({
  margin: '15px 0',
  background: colors.neutralLight.N0,

  '& .MuiDataGrid-columnHeaders': {
    color: theme.palette.secondary.dark,
    fontSize: 16,
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus':
    {
      outline: 'none',
    },
  '&.MuiDataGrid-root .MuiDataGrid-columnSeparator': {
    visibility: 'hidden',
  },
  '&.MuiDataGrid-root .MuiDataGrid-iconButtonContainer:focus': {
    outline: 'none',
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer': {
    visibility: 'visible',
  },
  '&.MuiDataGrid-toolbarContainer': {
    color: theme.palette.primary.main,
  },
  '.MuiDataGrid-row': {
    color: theme.palette.secondary.dark,
    cursor: 'pointer',
  },
  '&.MuiDataGrid-root .MuiDataGrid-footerContainer': {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export const ExtPreProButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 20,
}));

export const ExtAPISectionTitle = styled(Box)(({ theme }) => ({
  fontSize: 18,
  margin: '20px 0',
}));

export const SAPreprocessorTitle = styled(Box)(({ theme }) => ({
  marginTop: 20,
  fontSize: 18,
}));

export const LoaderStyled = styled(LinearProgress)(({ theme }) => ({
  margin: '20px 0',
}));

export const IngestionContainer = styled(Box)(({ theme }) => ({
  margin: '15px 0',
  fontSize: 16,

  [theme.breakpoints.down('sm')]: {
    fontSize: 14,
  },
}));

export const IngestionTitle = styled(Box)(({ theme }) => ({
  marginBottom: 10,
}));
