/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  ActionButton,
  AlertBox,
  LinearProgressBox,
  StyledLinearBar,
  SubtitleTypography,
} from './statusAlert.styled';

const StatusAlert = ({
  isLatestReport,
  reportData,
  status,
  handleGenerateReport,
  isGenerateReportLoading,
}) => {
  const { t } = useTranslation();
  const statusConfig = {
    ARCHIVED_REPORT_ERROR: {
      title: t(
        'newMarketIntelligencePage.reports.noReportLayout.archivedReportError.title',
      ),
      subtitle: t(
        'newMarketIntelligencePage.reports.noReportLayout.archivedReportError.subtitle',
      ),
    },
    ERROR: {
      title: t('newMarketIntelligencePage.reports.noReportLayout.error.title'),
      subtitle: t(
        'newMarketIntelligencePage.reports.noReportLayout.error.subtitle',
      ),
      actionText: t(
        'newMarketIntelligencePage.reports.noReportLayout.error.actionText',
      ),
    },
    CREATING: {
      title: t(
        'newMarketIntelligencePage.reports.noReportLayout.creating.title',
      ),
      subtitle: t(
        'newMarketIntelligencePage.reports.noReportLayout.creating.subtitle',
      ),
    },
    PLANNED: {
      title: t(
        'newMarketIntelligencePage.reports.noReportLayout.planned.title',
      ),
      subtitle: t(
        'newMarketIntelligencePage.reports.noReportLayout.planned.subtitle',
      ),
    },
    OBSOLETE: {
      title: t(
        'newMarketIntelligencePage.reports.noReportLayout.obsolete.title',
      ),
      subtitle: t(
        'newMarketIntelligencePage.reports.noReportLayout.obsolete.subtitle',
      ),
    },
    null: {
      title: t('newMarketIntelligencePage.reports.noReportLayout.null.title'),
      subtitle: t(
        'newMarketIntelligencePage.reports.noReportLayout.null.subtitle',
      ),
      actionText: t(
        'newMarketIntelligencePage.reports.noReportLayout.null.actionText',
      ),
    },
    pending: {
      title: t(
        'newMarketIntelligencePage.reports.noReportLayout.pending.title',
      ),
      subtitle: t(
        'newMarketIntelligencePage.reports.noReportLayout.pending.subtitle',
      ),
    },
  };

  const getStatusKey = (isLatestReport, reportData, status) => {
    if (isLatestReport) return status;
    if (
      reportData?.error === null ||
      (reportData?.status !== 'ERROR' && status !== null)
    ) {
      return 'ERROR';
    }
    return status in statusConfig ? status : null;
  };

  const statusKey = getStatusKey(isLatestReport, reportData, status);
  const { title, subtitle, actionText } =
    statusConfig[statusKey] || statusConfig[null];

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (statusKey === 'CREATING' || statusKey === 'PLANNED') {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            return 0;
          }
          const diff = Math.random() * 5;
          return Math.min(oldProgress + diff, 100);
        });
      }, 500);

      return () => {
        clearInterval(timer);
      };
    }
  }, [statusKey]);

  const handleActionClick = () => {
    if (statusKey === 'ERROR') {
      handleGenerateReport();
    } else if (statusKey === null) {
      handleGenerateReport();
    }
  };

  return (
    <AlertBox>
      <Typography variant="h3">{title}</Typography>
      <SubtitleTypography variant="body1">{subtitle}</SubtitleTypography>

      {actionText ? (
        <ActionButton
          variant="contained"
          onClick={handleActionClick}
          loading={isGenerateReportLoading}
        >
          {actionText}
        </ActionButton>
      ) : (
        <LinearProgressBox>
          <StyledLinearBar value={progress} />
        </LinearProgressBox>
      )}
    </AlertBox>
  );
};

StatusAlert.propTypes = {
  isLatestReport: PropTypes.bool,
  reportData: PropTypes.object,
  status: PropTypes.string,
  handleGenerateReport: PropTypes.func,
  isGenerateReportLoading: PropTypes.bool,
};

export default StatusAlert;
