/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { ArrowForwardIos } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  ButtonGroup,
  Grid,
  styled,
  TextField,
  Autocomplete,
  CircularProgress,
  MenuItem,
} from '@mui/material';

export const BoxWrapper = styled(Box)(() => ({
  width: '100%',
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}));

export const ButtonLink = styled(Button)(({ theme }) => ({
  height: '38px',
  //width: '100%',
  float: 'right',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  // [theme.breakpoints.up('sm')]: {
  //   // marginBottom: theme.spacing(0),
  //   //float: 'right',
  // },
  // [theme.breakpoints.up('md')]: {
  //   width: '220px',
  // },
}));

export const ButtonWrapper = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  marginTop: theme.spacing(3),
  display: 'flex',
  [theme.breakpoints.up('xs')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

export const InputWrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'left',
  flexDirection: 'column',
  alignContent: 'center',
  width: 'fit-content',
  marginBottom: theme.spacing(1),
}));

export const InputField = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const AccordionBox = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(2),
}));

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  padding: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
}));

export const StyledAccordionSummary = styled((props) => (
  <AccordionSummary
    expandIcon={
      <ArrowForwardIos
        color="primary"
        sx={{ fontSize: '1.25rem', marginBottom: '4px' }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  justifyContent: 'space-between',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

export const AccordionGrid = styled(Grid)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

export const ButtonGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.up('xs')]: {
    justifyContent: 'center',
  },
  justifyContent: 'space-evenly',
  alignItems: 'center',
}));

export const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.up('xs')]: {
    width: '100%',
  },
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

export const StyledButtonLeft = styled(LoadingButton)(({ theme }) => ({
  paddingRight: theme.spacing(5),
  paddingLeft: theme.spacing(5),
  [theme.breakpoints.up('xs')]: {
    width: '100%',
  },
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

export const StyledButtonRight = styled(LoadingButton)(({ theme }) => ({
  paddingRight: theme.spacing(5),
  paddingLeft: theme.spacing(5),
  [theme.breakpoints.up('xs')]: {
    width: '70%',
  },
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

export const ToggleWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'end',
}));

export const AutoComplete = styled(Autocomplete)(({ theme }) => ({
  width: '100%',
}));

export const LoadingProgress = styled(CircularProgress)(({ theme }) => ({
  position: 'absolute',
  right: '10px',
}));

export const MenuList = styled(MenuItem)(({ theme }) => ({
  paddingLeft: '16px !important',
}));

export const BoxContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
}));

export const SwitchFieldRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: 10,
  fontSize: 16,
  marginBottom: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    fontSize: 13,
  },
}));
