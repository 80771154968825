import React, { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import {
  Typography,
  Grid,
  Box,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import StorageIcon from '@mui/icons-material/Storage';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { SiteConfigContext } from '../../hooks/useSiteConfigContext';
import PageContainer from '../../components/pageContainer';
import {
  useLazyGetDataSourceQuery,
  useAddMarketIntelligenceConfigurationMutation,
  useLazyGetAllFileCollectionsForTableQuery,
} from '../../redux/services/speciphicAsk';
import { pushToast } from '../../redux/reducers/toasts.slice';
import { SECTIONS } from '../../constants/drawer';
import { AZURE_BLOB_TYPE, AWS_S3_TYPE } from '../../constants/dataSources';
import {
  LoadingIndicator,
  StyledFormControl,
  TagsChip,
  MenuBox,
} from './styled';
import UnauthorizedMessage from '../../components/unauthorized';
import DialogComponent from '../../components/app.dialog';

const translationJSONPrefix = `appConfigurationSection.marketIntelligence`;

const unauthorizedTranslationPrefix = `${translationJSONPrefix}.unauthorizedMessages`;

const MarketIntelligenceConfiguration = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [dataSourceList, setDataSourceList] = useState([]);
  const [reportFileCollectionList, setReportFileCollectionList] = useState([]);
  const [dataFileCollectionList, setDataFileCollectionList] = useState([]);
  const [selectedValues, setSelectedValues] = useState({
    dataSourceId: '',
    reportFileCollectionId: '',
    dataFileCollectionId: '',
    stocksLogosDataSourceId: '',
  });
  const [marketConfig, setMarketConfig] = useState({});
  const { getMarketIntelligenceConfig } = useContext(SiteConfigContext);
  const [isDrawerOpen, setDrawerOpen] = useState(true);

  const [alertDialogOpen, setAlertDialogOpen] = useState(false);

  useEffect(() => {
    const marketIntelligenceConfig = getMarketIntelligenceConfig();

    if (marketIntelligenceConfig) {
      setMarketConfig(marketIntelligenceConfig);
      setSelectedValues({
        dataSourceId: marketIntelligenceConfig?.process_data_source_id,
        reportFileCollectionId:
          marketIntelligenceConfig?.process_report_file_collection_id,
        dataFileCollectionId:
          marketIntelligenceConfig?.process_data_file_collection_id,
        stocksLogosDataSourceId:
          marketIntelligenceConfig?.stocks_logos_data_source_id,
      });
    }
  }, [getMarketIntelligenceConfig()]);

  useEffect(() => {
    if (marketConfig) {
      setSelectedValues({
        dataSourceId: marketConfig.process_data_source_id || '',
        dataFileCollectionId:
          marketConfig.process_data_file_collection_id || '',
        reportFileCollectionId:
          marketConfig.process_report_file_collection_id || '',
        stocksLogosDataSourceId: marketConfig.stocks_logos_data_source_id || '',
      });
    }
  }, [marketConfig]);

  const [
    getDataSources,
    {
      data: dataSources = [],
      error: dataSourcesError,
      isError: isGetDataSourcesError,
      isLoading: isGetDataSourceLoading,
      isSuccess: isGetDataSourcesSuccess,
    },
  ] = useLazyGetDataSourceQuery({});

  useEffect(() => {
    getDataSources({
      query: '',
      pageNo: 1,
      pageSize: 150,
    });
  }, []);

  const [
    getFileCollections,
    {
      data: fileCollections = [],
      error: fileCollectionsError,
      isError: isGetFileCollectionsError,
      isLoading: isGetFileCollectionsLoading,
      isSuccess: isGetFileCollectionsSuccess,
    },
  ] = useLazyGetAllFileCollectionsForTableQuery();

  useEffect(() => {
    getFileCollections({
      query: '',
      pageNo: 1,
      pageSize: 150,
    });
  }, []);

  const [
    addMarketIntelligenceConfiguration,
    {
      data: marketIntelligenceConfiguration,
      error: marketIntelligenceConfigError,
      isSuccess: isAddMarketIntelligenceConfigurationSuccess,
      isLoading: isAddMarketIntelligenceConfigurationLoading,
      isError: isAddMarketIntelligenceConfigurationError,
    },
  ] = useAddMarketIntelligenceConfigurationMutation();

  useEffect(() => {
    if (dataSources && isGetDataSourcesSuccess) {
      const filteredDataSources = dataSources?.data
        ?.filter(
          (source) =>
            source.type === AZURE_BLOB_TYPE || source.type === AWS_S3_TYPE,
        )
        .sort((a, b) => a.name.localeCompare(b.name));
      setDataSourceList(filteredDataSources);
    }
  }, [dataSources, isGetDataSourcesSuccess]);

  useEffect(() => {
    if (fileCollections) {
      setDataFileCollectionList(fileCollections?.data);
      setReportFileCollectionList(fileCollections?.data);
    }
  }, [fileCollections, isGetFileCollectionsSuccess]);

  useEffect(() => {
    if (isAddMarketIntelligenceConfigurationSuccess) {
      dispatch(
        pushToast({
          message: t(`${translationJSONPrefix}.alertMessages.configSuccess`),
          severity: 'success',
        }),
      );
    }
  }, [isAddMarketIntelligenceConfigurationSuccess]);

  const handleValueChange = (event, stateVariable) => {
    setSelectedValues((prevState) => ({
      ...prevState,
      [stateVariable]: event.target.value,
    }));
  };

  const handleFinalSubmit = () => {
    addMarketIntelligenceConfiguration({
      dataSourceId: selectedValues?.dataSourceId,
      dataFileCollectionId: selectedValues?.dataFileCollectionId,
      reportFileCollectionId: selectedValues?.reportFileCollectionId,
      stocksLogosDataSourceId: selectedValues?.stocksLogosDataSourceId,
    });

    setAlertDialogOpen(false);
  };

  const handleSubmitForm = () => {
    if (marketConfig?.process_data_source_id !== selectedValues?.dataSourceId) {
      setAlertDialogOpen(true);
      return;
    }

    handleFinalSubmit();
  };

  const isValuesChanged = () => {
    return (
      marketConfig?.process_data_source_id !== selectedValues?.dataSourceId ||
      marketConfig?.process_report_file_collection_id !==
        selectedValues?.reportFileCollectionId ||
      marketConfig?.process_data_file_collection_id !==
        selectedValues?.dataFileCollectionId ||
      marketConfig?.stocks_logos_data_source_id !==
        selectedValues?.stocksLogosDataSourceId
    );
  };

  const formatDataSourceName = (dataSource) => {
    const formattedType = dataSource
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
    return formattedType;
  };

  if (
    dataSourcesError?.status === 403 ||
    fileCollectionsError?.status === 403 ||
    marketIntelligenceConfigError?.status === 403
  )
    return (
      <UnauthorizedMessage
        drawer={SECTIONS.APP_CONFIGURATION}
        isDrawerOpen={isDrawerOpen}
        description={t(`${unauthorizedTranslationPrefix}.list`)}
      />
    );

  return (
    <PageContainer
      isDrawerOpen={(res) => setDrawerOpen(res)}
      drawer={SECTIONS.APP_CONFIGURATION}
    >
      <DialogComponent
        open={alertDialogOpen}
        setOpen={setAlertDialogOpen}
        title={t(`${translationJSONPrefix}.alertDialog.title`)}
        content={t(`${translationJSONPrefix}.alertDialog.content`)}
        onSubmit={(e) => {
          handleFinalSubmit();
        }}
        isDeleteLoading={isAddMarketIntelligenceConfigurationLoading}
        cancelBtnLabel={t(
          `${translationJSONPrefix}.alertDialog.buttons.cancel`,
        )}
        submitBtnLabel={t(`${translationJSONPrefix}.alertDialog.buttons.yes`)}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography>{t(`${translationJSONPrefix}.header`)}</Typography>
        </Grid>
        <Grid item container xs={12} sm={12} md={6} spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <StyledFormControl fullWidth size="small">
              <InputLabel>
                {t(`${translationJSONPrefix}.dataSourceInputLabel`)}
              </InputLabel>
              <Select
                value={selectedValues?.dataSourceId}
                onChange={(event) => handleValueChange(event, 'dataSourceId')}
                fullWidth
                label={t(`${translationJSONPrefix}.processDataSourcesLabel`)}
                size="small"
              >
                {dataSourceList.map((source, index) => (
                  <MenuItem key={index} value={source?.id}>
                    <MenuBox>
                      <Typography>{source?.name}</Typography>
                      <TagsChip
                        icon={<StorageIcon />}
                        label={formatDataSourceName(source.type)}
                      />
                    </MenuBox>
                  </MenuItem>
                ))}
              </Select>
              {isGetDataSourceLoading && <LoadingIndicator />}
            </StyledFormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <StyledFormControl fullWidth size="small">
              <InputLabel>
                {t(`${translationJSONPrefix}.fileInputLabel`)}
              </InputLabel>
              <Select
                value={selectedValues?.dataFileCollectionId}
                onChange={(event) =>
                  handleValueChange(event, 'dataFileCollectionId')
                }
                fullWidth
                label={t(`${translationJSONPrefix}.processDataFileCollection`)}
                size="small"
              >
                {dataFileCollectionList?.map((source, index) => (
                  <MenuItem key={index} value={source?.id}>
                    {source?.name}
                  </MenuItem>
                ))}
              </Select>
              {isGetFileCollectionsLoading && <LoadingIndicator />}
            </StyledFormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <StyledFormControl fullWidth size="small">
              <InputLabel>
                {t(`${translationJSONPrefix}.reportInputLabel`)}
              </InputLabel>
              <Select
                value={selectedValues?.reportFileCollectionId}
                onChange={(event) =>
                  handleValueChange(event, 'reportFileCollectionId')
                }
                fullWidth
                label={t(`${translationJSONPrefix}.reportFileCollection`)}
                size="small"
              >
                {reportFileCollectionList?.map((source, index) => (
                  <MenuItem key={index} value={source?.id}>
                    {source?.name}
                  </MenuItem>
                ))}
              </Select>
              {isGetFileCollectionsLoading && <LoadingIndicator />}
            </StyledFormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <StyledFormControl fullWidth size="small">
              <InputLabel>
                {t(`${translationJSONPrefix}.stocksLogosDataSourceLabel`)}
              </InputLabel>
              <Select
                value={selectedValues?.stocksLogosDataSourceId}
                onChange={(event) =>
                  handleValueChange(event, 'stocksLogosDataSourceId')
                }
                fullWidth
                label={t(`${translationJSONPrefix}.stocksLogosDataSourceLabel`)}
                size="small"
              >
                {dataSourceList.map((source, index) => (
                  <MenuItem key={index} value={source?.id}>
                    <MenuBox>
                      <Typography>{source?.name}</Typography>
                      <TagsChip
                        icon={<StorageIcon />}
                        label={formatDataSourceName(source.type)}
                      />
                    </MenuBox>
                  </MenuItem>
                ))}
              </Select>
              {isGetDataSourceLoading && <LoadingIndicator />}
            </StyledFormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <LoadingButton
                size="small"
                variant="contained"
                disabled={!isValuesChanged()}
                onClick={handleSubmitForm}
                loading={isAddMarketIntelligenceConfigurationLoading}
              >
                {t(`${translationJSONPrefix}.submitButton`)}
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default MarketIntelligenceConfiguration;
