import { LoadingButton } from '@mui/lab';
import { Box, LinearProgress, styled, Typography } from '@mui/material';

export const StyledLinearBar = styled(LinearProgress)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  '& .MuiLinearProgress-bar': {
    backgroundColor: theme.palette.secondary.main,
  },
  borderRadius: 5,
  height: 6,
}));

export const AlertBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: theme.spacing(5),
}));

export const ActionButton = styled(LoadingButton)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  marginTop: theme.spacing(2),
}));

export const LinearProgressBox = styled(Box)(({ theme }) => ({
  width: '60%',
  marginTop: theme.spacing(2),
}));

export const SubtitleTypography = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));
