/**
 * Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 *
 */
import React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { LinearProgress, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Delete, Add, Storage } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import {
  LoadingTypography,
  TableHeader,
  FileNameContainer,
  FileName,
  FileNameText,
  TagsChip,
  AlignmentBox,
  ButtonStyled,
  ButtonsWrapper,
} from './individualDataSourcePage.styled';
import PageContainer from '../../components/pageContainer';
import AppShadowBox from '../../components/app.shadowbox';
import { ALLOWED_FILE_TYPES, ALLOWED_FILE_SIZE } from '../../constants/files';
import DataGridTable from '../../components/datagrid';
import {
  useAddFilesToDataSourceMutation,
  useDeleteFilesFromDataSourceMutation,
  useGetDataSourceQuery,
  useLazyGetDataSourceFilesQuery,
} from '../../redux/services/speciphicAsk';
import { useDispatch } from 'react-redux';
import { pushToast } from '../../redux/reducers/toasts.slice';
import ConfirmationDialog from '../../components/confirmationDialog';
import DisplayTime from '../../components/timezone.component';
import UnauthorizedMessage from '../../components/unauthorized';
import { formatBytes } from '../../utils/functions';
import DialogComponent from '../../components/app.dialog';
import useDebounce from '../../hooks/useDebounce';
import { DEBOUNCE_DELAY } from '../../constants/debounce';

const PAGE_SIZE = 50;

const translationJSONPrefix = 'individualDataSourcePage';

const unauthorizedTranslationPrefix = `${translationJSONPrefix}.unauthorizedMessages`;

const IndividualDataSourcePage = () => {
  const [newFiles, setNewFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [isAddFiles, setIsAddFiles] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { rowId: dataSourceId } = useParams();
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteDialogTFValue, setDeleteDialogTFValue] = useState('');
  const [dialogDeleteButtonDisable, setDDBDisable] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');

  const allowedFileTypes = {
    ...ALLOWED_FILE_TYPES['text'],
    ...ALLOWED_FILE_TYPES['pdf'],
    ...ALLOWED_FILE_TYPES['docx'],
    ...ALLOWED_FILE_TYPES['html'],
    ...ALLOWED_FILE_TYPES['md'],
    ...ALLOWED_FILE_TYPES['jpeg'],
    ...ALLOWED_FILE_TYPES['png'],
    ...ALLOWED_FILE_TYPES['bmp'],
    ...ALLOWED_FILE_TYPES['tiff'],
  };

  const onFileDrop = useCallback((acceptedFiles, fileRejections) => {
    setNewFiles(acceptedFiles);
    setRejectedFiles(fileRejections);
    setIsAddFiles(true);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onFileDrop,
    accept: allowedFileTypes,
    maxSize: ALLOWED_FILE_SIZE,
  });

  const {
    data: individualDataSource,
    error: individualDataSourceError,
    isLoading: isGetIndividualDataSourceLoading,
    isSuccess: isGetIndividualDataSourceSuccess,
  } = useGetDataSourceQuery({
    storageId: dataSourceId,
  });

  const [
    getDataSourceFiles,
    {
      data: individualDataSourceFiles = {},
      error: individualDataSourceFilesError,
      isFetching: isGetFilesFetcing,
    },
  ] = useLazyGetDataSourceFilesQuery();

  useDebounce(
    () => {
      getDataSourceFiles({
        dataSourceId: dataSourceId,
        pageNumber,
        pageSize,
        query: searchQuery,
      });
    },
    [pageNumber, pageSize, searchQuery],
    DEBOUNCE_DELAY,
  );

  const [
    addFilesToDataSource,
    {
      data: addFiles,
      isSuccess: isAddFilesSuccess,
      isLoading: isAddFilesLoading,
      isError: isAddFilesError,
    },
  ] = useAddFilesToDataSourceMutation();

  const [
    deleteFilesFromContainer,
    {
      data: deleteFile = [],
      isSuccess: isDeleteFileSuccess,
      isLoading: isDeleteFileLoading,
      isError: isDeleteFileError,
    },
  ] = useDeleteFilesFromDataSourceMutation();

  useEffect(() => {
    if (isAddFilesSuccess) {
      setIsAddFiles(false);
      dispatch(
        pushToast({
          message: t(
            'individualDataSourcePage.alertMessages.updateResultSuccess',
          ),
          severity: 'success',
        }),
      );
    }
  }, [isAddFilesSuccess]);

  useEffect(() => {
    if (individualDataSourceFiles && individualDataSourceFiles.uploadedFiles) {
      const formattedFiles = individualDataSourceFiles.uploadedFiles.map(
        (dsFile) => ({
          name: dsFile?.filename,
          size: dsFile?.size,
          lastModified: dsFile?.lastModified,
        }),
      );
      setFiles(formattedFiles);
    }
  }, [individualDataSourceFiles]);

  function getColumnsForFilesDataGrid() {
    const filesDataGridColumns = [
      {
        field: 'name',
        headerName: t('individualDataSourcePage.labels.fileName'),
        minWidth: 400,
        flex: 1,
        sortable: false,
      },
      {
        field: 'size',
        headerName: 'Size',
        minWidth: 150,
        flex: 0.5,
        valueGetter: (params) => formatBytes(params.row?.size),
        sortable: false,
      },
      {
        field: 'lastModified',
        headerName: 'Last Modified',
        minWidth: 300,
        flex: 0.8,
        sortable: false,
      },
    ];

    return filesDataGridColumns;
  }

  const handleUploadFilesClick = () => {
    if (isAddFiles) {
      addFilesToDataSource({
        dataSourceId: dataSourceId,
        files: newFiles,
      });
    }
  };

  const handleCancelUploadClick = () => {
    setIsAddFiles(false);
    setNewFiles([]);
  };

  const formateDataSourceText = (dataSourceType) => {
    const formattedType = dataSourceType
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
    return <Typography>{formattedType}</Typography>;
  };

  if (
    individualDataSourceError?.status === 403 ||
    individualDataSourceFilesError?.status === 403
  )
    return (
      <UnauthorizedMessage
        description={t(`${unauthorizedTranslationPrefix}.list`)}
      />
    );

  return (
    <PageContainer>
      <DialogComponent
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        setTextFieldValue={setDeleteDialogTFValue}
        textField={{
          name: 'name',
          value: deleteDialogTFValue,
          onChange: (e) => {
            setDeleteDialogTFValue(e.target.value);

            if (selectedFiles.length > 1) {
              if (e.target.value === 'permanently delete') setDDBDisable(false);
              else setDDBDisable(true);
              return;
            }

            if (
              selectedFiles.length === 1 &&
              e.target.value === selectedFiles[0]
            )
              setDDBDisable(false);
          },
          placeholder:
            selectedFiles.length > 1
              ? t(`${translationJSONPrefix}.labels.deletePlaceholder`)
              : `${t(
                  `${translationJSONPrefix}.labels.deleteSinglePlacehoderPrefix`,
                )} '${selectedFiles[0]}'`,
        }}
        title={`${t(`${translationJSONPrefix}.labels.deleteTitle`)}`}
        content={
          selectedFiles.length > 1
            ? t(`${translationJSONPrefix}.labels.deleteMessage`)
            : t(`${translationJSONPrefix}.labels.deleteSingle`)
        }
        highlightedTitle={
          selectedFiles.length > 1
            ? null
            : t(`${translationJSONPrefix}.labels.highlightTitle`)
        }
        highlightedContent={selectedFiles.length > 1 ? null : selectedFiles[0]}
        onSubmit={(e) => {
          setDDBDisable(true);
          deleteFilesFromContainer({
            dataSourceId,
            fileNames: selectedFiles,
          });

          getDataSourceFiles({
            dataSourceId: dataSourceId,
            pageNumber,
            pageSize,
            query: searchQuery,
          });
          setDeleteDialogOpen(false);
        }}
        submitBtnDisabled={dialogDeleteButtonDisable}
      />

      <AppShadowBox>
        {isGetIndividualDataSourceLoading ? (
          <>
            <LinearProgress />
            <LoadingTypography variant="h5">
              {t('individualDataSourcePage.pageLoading')}
            </LoadingTypography>
          </>
        ) : (
          <>
            <TableHeader>
              <FileNameContainer>
                <FileName disablePadding disableGutters>
                  <FileNameText
                    primary={
                      <Typography variant="h2">
                        {individualDataSource.name}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="CAPTION TEXT">
                        {individualDataSource.id}
                      </Typography>
                    }
                  ></FileNameText>
                </FileName>
                <TagsChip
                  icon={<Storage />}
                  label={formateDataSourceText(individualDataSource.type)}
                />
              </FileNameContainer>

              <ButtonsWrapper>
                <ButtonStyled
                  size="medium"
                  variant="outlined"
                  loadingPosition="end"
                  startIcon={<Delete />}
                  fullWidth
                  disabled={selectedFiles.length === 0}
                  onClick={() => setDeleteDialogOpen(true)}
                >
                  {t(`${translationJSONPrefix}.buttonTexts.deleteButton`)}
                </ButtonStyled>

                <ButtonStyled
                  size="medium"
                  variant="contained"
                  loadingPosition="end"
                  isDragActive={isDragActive}
                  {...getRootProps()}
                  startIcon={<Add />}
                  fullWidth
                >
                  {t('individualDataSourcePage.buttonTexts.addButton')}
                  <input {...getInputProps()} />
                </ButtonStyled>
              </ButtonsWrapper>
            </TableHeader>
            <AlignmentBox>
              <Typography>
                {t('individualDataSourcePage.labels.containerName')}
                <b>
                  {' '}
                  {individualDataSource.containerName ||
                    individualDataSource.bucketName}
                </b>
              </Typography>
              <Typography>
                {individualDataSource.lastUpdatedBy ? (
                  <>
                    {t('individualDataSourcePage.labels.updatedFound')}{' '}
                    {individualDataSource.lastUpdatedBy}{' '}
                    {t('individualDataSourcePage.labels.at')}
                    <DisplayTime
                      time={individualDataSource.lastUpdatedDate}
                      format="D MMMM, YYYY hh.mm A"
                    />
                  </>
                ) : (
                  <>{t('individualDataSourcePage.labels.updateNotFound')}</>
                )}
              </Typography>
            </AlignmentBox>
            <DataGridTable
              isNextPageLoading={isGetFilesFetcing}
              row={files.map((f, i) => {
                return { id: i, ...f };
              })}
              rowId={'name'}
              column={getColumnsForFilesDataGrid()}
              pageSize={pageSize}
              serverSideSearch={true}
              onSearch={(e) => setSearchQuery(e)}
              isSearching={isGetFilesFetcing}
              checkboxSelection
              disableSelectionOnClick={false}
              isPaginationRequired={true}
              customPageNo={pageNumber}
              customPageSize={pageSize}
              onCustomPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              onCustomPageChange={(newPage) => setPageNumber(newPage)}
              totalDisplayText={t(
                'individualDataSourcePage.labels.totalDisplayText',
              )}
              onSelectionModelChange={setSelectedFiles}
            />
          </>
        )}
      </AppShadowBox>

      <ConfirmationDialog
        open={isAddFiles}
        title={t('individualDataSourcePage.labels.uploadTitle', {
          total: newFiles.length,
        })}
        messageLine1={t('individualDataSourcePage.labels.uploadMessage1')}
        messageLine2={
          rejectedFiles.length > 0
            ? t('individualDataSourcePage.alertMessages.uploadRejectedFiles', {
                total: rejectedFiles.length,
              })
            : ''
        }
        alertSeverity={t('individualDataSourcePage.alertMessages.warning')}
        onYesClick={handleUploadFilesClick}
        onNoClick={handleCancelUploadClick}
        noButtonLabel={t('individualDataSourcePage.buttonTexts.cancelButton')}
        yesButtonLabel={t('individualDataSourcePage.buttonTexts.uploadButton')}
        yesButtonLoading={isAddFilesLoading}
      />
    </PageContainer>
  );
};

IndividualDataSourcePage.propTypes = {
  id: PropTypes.string,
};

export default IndividualDataSourcePage;
