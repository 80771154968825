/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {
  Box,
  DialogActions,
  IconButton,
  styled,
  Icon,
  Grid,
  Button,
  Chip,
  Typography,
  LinearProgress,
  CircularProgress,
} from '@mui/material';
import { Search, Clear } from '@mui/icons-material';
import colors from '../../../../../themes/colors';
export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 'none',
  '& .MuiDataGrid-columnHeaders': {
    color: theme.palette.secondary.dark,
    fontSize: 16,
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus':
    {
      outline: 'none',
    },
  '&.MuiDataGrid-root .MuiDataGrid-columnSeparator': {
    visibility: 'hidden',
  },
  '&.MuiDataGrid-root .MuiDataGrid-iconButtonContainer:focus': {
    outline: 'none',
  },
  '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer': {
    visibility: 'visible',
  },
  '&.MuiDataGrid-toolbarContainer': {
    color: theme.palette.primary.main,
  },
  '.MuiDataGrid-row': {
    cursor: 'pointer',
    color: theme.palette.secondary.dark,
  },
  '&.MuiDataGrid-root .MuiDataGrid-footerContainer': {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export const DeleteButton = styled(IconButton)(() => ({
  color: colors.red.R500,
}));

export const DeleteFileButton = styled(IconButton)(() => ({
  color: colors.red.R500,
}));

export const SearchBar = styled(GridToolbar)(({ theme }) => ({
  color: theme.palette.primary.main,
  padding: theme.spacing(0),
}));

export const StyledSearchIcon = styled(Search)(({ theme }) => ({
  color: theme.palette.primary.main,
  padding: theme.spacing(0),
}));
export const StyledClearIcon = styled(Clear)(({ theme }) => ({
  color: theme.palette.primary.main,
  padding: theme.spacing(0),
}));

export const StyledFileWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: '100%',
}));

export const OverflowWrapper = styled(Box)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '100%',
}));

export const DialogActionsWrapper = styled(DialogActions)(({ theme }) => ({
  paddingRight: theme.spacing(3),
  paddingBottom: theme.spacing(3),
}));

export const StyledFileUploadIcon = styled(Icon)(({ theme }) => ({
  height: 20,
  marginRight: theme.spacing(0.5),
}));

export const StyledToolbarGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const StyledFileUploadButton = styled(Button)(({ theme }) => ({
  float: 'right',
  [theme.breakpoints.up('xs')]: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
    marginTop: theme.spacing(0),
  },
}));

export const TagsChip = styled(Chip)(({ theme }) => ({
  marginRight: theme.spacing(1),
  minWidth: '20px',
  width: 'fit-content',
}));

export const PaginationTextTypography = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(0.3),
}));

export const ProgressBar = styled(LinearProgress)({
  width: '100%',
});

export const LoadingProgress = styled(CircularProgress)(({ theme }) => ({
  width: '16px !important',
  height: '16px !important',
}));
