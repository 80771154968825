/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PageContainer from '../../../components/pageContainer';
import { SECTIONS } from '../../../constants/drawer';
import { APP_CONFIGURATION } from '../../../constants/path';
import {
  ADD_PAGE,
  EXTERNAL_EMBEDDING_CONFIG,
} from '../../../constants/appConfigurationPaths';
import Header from '../../../components/form/header.component';
import { DataGridWrapper } from '../styled';
import Loader from '../../../components/loader';
import DataGridTable from '../../../components/datagrid';
import DialogComponent from '../../../components/app.dialog';
import { filterByIds, sortByDateField } from '../../../utils/functions';
import { useDispatch } from 'react-redux';
import { pushToast } from '../../../redux/reducers/toasts.slice';
import {
  useGetAllExternalAPIQuery,
  useDeleteManyExternalAPIMutation,
} from '../../../redux/services/speciphicAsk';
import { EXTERNAL_EMBEDDINGS_API_ENDPOINT } from '../../../constants/externalAPIEndpoints';

const translationJSONPrefix = 'appConfigurationSection.externalEmbedding';

const addPageRoute = `${APP_CONFIGURATION}${EXTERNAL_EMBEDDING_CONFIG.endpoint}${ADD_PAGE.endpoint}`;

const columns = (t) => {
  const translationPrefix = `${translationJSONPrefix}.table.columnHeaders`;

  return [
    {
      field: 'httpMethod',
      headerName: t(`${translationPrefix}.httpMethod`),
      flex: 0.2,
      minWidth: 70,
      valueGetter: (params) => `${params.row?.httpMethod || '-'}`,
    },
    {
      field: 'name',
      headerName: t(`${translationPrefix}.name`),
      flex: 0.3,
      minWidth: 200,
      valueGetter: (params) => `${params.row?.name || '-'}`,
    },
    {
      field: 'apiEndpoint',
      headerName: t(`${translationPrefix}.apiEndpoint`),
      flex: 0.8,
      minWidth: 250,
      valueGetter: (params) => `${params.row?.apiEndpoint || '-'}`,
    },
    {
      field: 'ext',
      headerName: t(`${translationPrefix}.ext`),
      flex: 0.5,
      minWidth: 200,
      valueGetter: (params) => `${params.row?.ext?.join(', ') || '-'}`,
    },
  ];
};

const ExternalEmbedddingsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [selectedCPs, setSelectedCPs] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteDialogTFValue, setDeleteDialogTFValue] = useState('');
  const [deleteDialogButtonDisabled, setDeleteDialogButtonDisabled] =
    useState(true);

  const { data, error, isFetching } = useGetAllExternalAPIQuery({
    endpoint: EXTERNAL_EMBEDDINGS_API_ENDPOINT,
  });

  const [
    deleteExternalPrePro,
    {
      data: deletedExtPrePro,
      error: deleteError,
      isError: isDeleteError,
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
    },
  ] = useDeleteManyExternalAPIMutation();

  useEffect(() => {
    if (deleteError?.status === 403) {
      dispatch(
        pushToast({
          message: t(`${translationJSONPrefix}.unauthorizedMessages.delete`),
          severity: 'error',
        }),
      );
    }
  }, [isDeleteError]);

  useEffect(() => {
    if (isDeleteSuccess) {
      setDeleteDialogOpen(false);
      setSelectedCPs([]);
    }
  }, [isDeleteSuccess]);

  const handleCellClick = (e) => {
    if (e?.field === 'name') navigate(`${addPageRoute}?id=${e?.row?.id}`);
  };

  useEffect(() => {
    setDeleteDialogButtonDisabled(true);
    setDeleteDialogTFValue('');
  }, [deleteDialogOpen]);

  return (
    <PageContainer
      isDrawerOpen={(res) => setIsDrawerOpen(res)}
      drawer={SECTIONS.APP_CONFIGURATION}
    >
      <DialogComponent
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        setTextFieldValue={setDeleteDialogTFValue}
        textField={{
          name: 'name',
          value: deleteDialogTFValue,
          onChange: (e) => {
            setDeleteDialogTFValue(e.target.value);

            if (selectedCPs.length > 1) {
              if (e.target.value === 'permanently delete')
                setDeleteDialogButtonDisabled(false);
              return;
            }

            const names = filterByIds(selectedCPs, data).map((p) => p.name);

            setDeleteDialogButtonDisabled(!(e.target.value === names[0]));
          },
          placeholder:
            selectedCPs.length > 1
              ? t(
                  `${translationJSONPrefix}.deleteDialog.textFieldPlaceholderPlural`,
                )
              : t(`${translationJSONPrefix}.deleteDialog.textFieldPlaceholder`),
        }}
        title={t(`${translationJSONPrefix}.deleteDialog.title`)}
        content={
          selectedCPs.length > 1
            ? t(`${translationJSONPrefix}.deleteDialog.contentPlural`)
            : t(`${translationJSONPrefix}.deleteDialog.content`)
        }
        highlightedTitle={
          selectedCPs.length === 1
            ? t(`${translationJSONPrefix}.deleteDialog.highlightedTitle`)
            : ''
        }
        highlightedContent={
          selectedCPs.length === 1
            ? filterByIds(selectedCPs, data)[0]?.name
            : ''
        }
        onSubmit={(e) => {
          deleteExternalPrePro({
            ids: selectedCPs,
            endpoint: EXTERNAL_EMBEDDINGS_API_ENDPOINT,
          });
        }}
        submitBtnDisabled={deleteDialogButtonDisabled}
        isDeleteLoading={isDeleteLoading}
      />
      <Header
        header={t(`${translationJSONPrefix}.title`)}
        addButtonLabel={t(`appConfigurationSection.components.header.add`)}
        addButtonOnClick={() => navigate(addPageRoute)}
        deleteButton={true}
        deleteButtonDisabled={selectedCPs.length === 0}
        deleteButtonOnClick={() => setDeleteDialogOpen(true)}
      />
      {isFetching ? (
        <Loader label={t(`${translationJSONPrefix}.loadingLabel`)} />
      ) : (
        <DataGridWrapper>
          <DataGridTable
            onCellClick={handleCellClick}
            row={sortByDateField([...data], 'lastUpdatedDate')}
            column={columns(t)}
            checkboxSelection
            autoHeight
            rowId={'id'}
            onSelectionModelChange={(newSelection) =>
              setSelectedCPs(newSelection)
            }
          />
        </DataGridWrapper>
      )}
    </PageContainer>
  );
};

export default ExternalEmbedddingsPage;
