/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React, { useEffect, useState } from 'react';
import { array, bool, object, string } from 'yup';
import { useTranslation } from 'react-i18next';
import { Form } from 'formik';

import PageContainer from '../../../components/pageContainer';
import { SECTIONS } from '../../../constants/drawer';
import AddPageHeader from '../../../components/form/addPageHeader.component';
import { APP_CONFIGURATION } from '../../../constants/path';
import { LANGUAGE_MODELS_CONFIG } from '../../../constants/appConfigurationPaths';
import {
  FormContainer,
  InputsContainer,
  ToogleButtonsContainer,
} from '../styled';
import { AlertStyled } from '../../settings/filesCollectionSettingsPage/styled';
import {
  useCreateLanguageModelMutation,
  useLazyGetLanguageModelQuery,
  useUpdateLanguageModelMutation,
} from '../../../redux/services/speciphicAsk';
import Loader from '../../../components/loader';
import AppForm, {
  AppSelectField,
  AppTextField,
  FormButtons,
  ToogleContainer,
} from '../../../components/form';
import { getChangedValues } from '../../../components/form/functions';
import { Button, IconButton } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { useSearchParams } from 'react-router-dom';

const translationJSONPrefix = 'appConfigurationSection.languageModelsConfig';
const mainPageRoute = `${APP_CONFIGURATION}${LANGUAGE_MODELS_CONFIG.endpoint}`;

const PROVIDERS = {
  CUSTOM: {
    label: 'Custom',
    value: 'custom_gpt',
  },
  OPENAI: {
    label: 'OpenAI',
    value: 'openai',
  },
  AZURE_OPENAI: {
    label: 'Azure OpenAI',
    value: 'azure_openai',
  },
  AWS_BEDROCK: {
    label: 'AWS Bedrock',
    value: 'aws_bedrock',
  },
};

const defaultDataState = {
  provider: PROVIDERS.CUSTOM.value,
  name: '',
  modelName: '',
  conversation: false,
  streaming: false,
  deploymentName: '',
  apiVersion: '',
  className: '',
  moduleName: '',
  extraParams: [{ key: '', value: '' }],
};

const formSchema = (t, provider) => {
  const translationPrefix = (label) =>
    `${translationJSONPrefix}.form.fields.${label}.errorMessages`;

  const stringField = (label) =>
    string()
      .required(t(`${translationPrefix(label)}.required`))
      .min(3, t(`${translationPrefix(label)}.min`))
      .max(250, t(`${translationPrefix(label)}.max`));

  if (
    provider === PROVIDERS.OPENAI.value ||
    provider === PROVIDERS.AWS_BEDROCK.value
  ) {
    return object({
      provider: string(),
      name: stringField('name'),
      modelName: stringField('modelName'),
      conversation: bool(),
      streaming: bool(),
    });
  }

  if (provider === PROVIDERS.AZURE_OPENAI.value) {
    return object({
      provider: string(),
      name: stringField('name'),
      modelName: stringField('modelName'),
      conversation: bool(),
      streaming: bool(),
      deploymentName: stringField('deploymentName'),
      apiVersion: stringField('apiVersion'),
    });
  }

  return object({
    provider: string(),
    name: stringField('name'),
    modelName: stringField('modelName'),
    conversation: bool(),
    streaming: bool(),
    className: stringField('className'),
    moduleName: stringField('moduleName'),
    extraParams: array().of(
      object().shape({
        key: string(),
        value: string(),
      }),
    ),
  });
};

const formRenderFunction = (
  { values, errors, touched, dirty, isSubmitting, resetForm, setFieldValue },
  formSending,
  isEdit,
  setSelectedProvider,
) => {
  const { t } = useTranslation();

  const translationPrefix = `${translationJSONPrefix}.form.fields`;

  return (
    <Form>
      <InputsContainer>
        <AppSelectField
          name="provider"
          values={values}
          label={t(`${translationPrefix}.provider.label`)}
          errors={errors}
          touched={touched}
          setFieldValue={(e, value) => {
            setFieldValue(e, value);
            setSelectedProvider(value);
          }}
          menuData={Object.keys(PROVIDERS).map((p) => {
            return {
              label: PROVIDERS[p].label,
              value: PROVIDERS[p].value,
            };
          })}
        />
      </InputsContainer>

      <InputsContainer>
        <AppTextField
          style={{ width: '100%' }}
          name="name"
          label={t(`${translationPrefix}.name.label`)}
          placeholder={t(`${translationPrefix}.name.placeholder`)}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
        />
      </InputsContainer>

      <InputsContainer>
        <AppTextField
          style={{ width: '100%' }}
          name="modelName"
          label={t(`${translationPrefix}.modelName.label`)}
          placeholder={t(`${translationPrefix}.modelName.placeholder`)}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
        />
      </InputsContainer>

      <ToogleButtonsContainer>
        <ToogleContainer
          name="streaming"
          label={t(`${translationPrefix}.streaming.label`)}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
        />

        <ToogleContainer
          name="conversation"
          label={t(`${translationPrefix}.conversation.label`)}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
        />
      </ToogleButtonsContainer>

      {values['provider'] === PROVIDERS.AZURE_OPENAI.value && (
        <InputsContainer>
          <AppTextField
            style={{ width: '100%' }}
            name="deploymentName"
            label={t(`${translationPrefix}.deploymentName.label`)}
            placeholder={t(`${translationPrefix}.deploymentName.placeholder`)}
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
          />
        </InputsContainer>
      )}

      {values['provider'] === PROVIDERS.AZURE_OPENAI.value && (
        <InputsContainer>
          <AppTextField
            style={{ width: '100%' }}
            name="apiVersion"
            label={t(`${translationPrefix}.apiVersion.label`)}
            placeholder={t(`${translationPrefix}.apiVersion.placeholder`)}
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
          />
        </InputsContainer>
      )}

      {values['provider'] === PROVIDERS.CUSTOM.value && (
        <InputsContainer>
          <AppTextField
            style={{ width: '100%' }}
            name="className"
            label={t(`${translationPrefix}.className.label`)}
            placeholder={t(`${translationPrefix}.className.placeholder`)}
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
          />
        </InputsContainer>
      )}

      {values['provider'] === PROVIDERS.CUSTOM.value && (
        <InputsContainer>
          <AppTextField
            style={{ width: '100%' }}
            name="moduleName"
            label={t(`${translationPrefix}.moduleName.label`)}
            placeholder={t(`${translationPrefix}.moduleName.placeholder`)}
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
          />
        </InputsContainer>
      )}

      {values['provider'] === PROVIDERS.CUSTOM.value && (
        <>
          <div>{t(`${translationPrefix}.extraParams.fieldName`)}</div>
          {values['extraParams'].map((ep, i) => (
            <InputsContainer key={i}>
              <AppTextField
                style={{ width: '100%' }}
                name={`extraParams`}
                label={t(`${translationPrefix}.extraParams.label.key.label`)}
                placeholder={t(
                  `${translationPrefix}.extraParams.label.key.placeholder`,
                )}
                value={values['extraParams'][i].key}
                onChange={(e) => {
                  const tempValues = [...values['extraParams']];
                  tempValues[i].key = e.target.value;
                  setFieldValue('extraParams', tempValues);
                }}
              />

              <AppTextField
                style={{ width: '100%' }}
                name={'extraParams'}
                label={t(`${translationPrefix}.extraParams.label.value.label`)}
                placeholder={t(
                  `${translationPrefix}.extraParams.label.value.placeholder`,
                )}
                value={values['extraParams'][i].value}
                onChange={(e) => {
                  const tempValues = [...values['extraParams']];
                  tempValues[i].value = e.target.value;
                  setFieldValue('extraParams', tempValues);
                }}
              />

              {i > 0 && (
                <IconButton
                  onClick={() => {
                    const tempParams = [...values['extraParams']];
                    tempParams.splice(i, 1);

                    setFieldValue('extraParams', tempParams);
                  }}
                >
                  <Delete />
                </IconButton>
              )}
            </InputsContainer>
          ))}
          <Button
            onClick={() => {
              setFieldValue('extraParams', [
                ...values['extraParams'],
                { key: '', value: '' },
              ]);
            }}
            variant="contained"
            startIcon={<Add />}
          >
            {t(`${translationPrefix}.extraParams.addButtonLabel`)}
          </Button>
        </>
      )}

      <FormButtons
        resetForm={resetForm}
        dirty={dirty}
        isSubmitting={isSubmitting || formSending}
      />
    </Form>
  );
};

const LanguageModelAddPage = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const [initialFormData, setInitialFormData] = useState(defaultDataState);
  const [formData, setFormData] = useState(defaultDataState);
  const [isEdit, setIsEdit] = useState(false);
  const [showAlert, setShowAlert] = useState({
    formUpdateError: false,
    formUpdateSuccess: false,
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedProvider, setSelectedProvider] = useState(
    PROVIDERS.CUSTOM.value,
  );

  const [getLanguageModel, { data, error, isLoading, isSuccess }] =
    useLazyGetLanguageModelQuery();

  const [
    createLanguageModel,
    {
      data: createdData,
      error: createError,
      isLoading: isCreateLoading,
      isSuccess: isCreateSuccess,
      isError: isCreateError,
    },
  ] = useCreateLanguageModelMutation();

  const [
    updateLanguageModel,
    {
      data: updatedData,
      error: updateError,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
    },
  ] = useUpdateLanguageModelMutation();

  useEffect(() => {
    const langModelId = searchParams.get('id');

    if (langModelId) {
      setIsEdit(langModelId);
      getLanguageModel({ id: langModelId });
    }
  }, []);

  useEffect(() => {
    if (isSuccess && data) {
      const tempFormData = {
        provider: data?.provider || PROVIDERS.CUSTOM.value,
        name: data?.name || '',
        modelName: data?.modelName || '',
        conversation: data?.capabilities?.conversation || false,
        streaming: data?.capabilities?.streaming || false,
        deploymentName: data?.deploymentName || '',
        apiVersion: data?.apiVersion || '',
        className: data?.className || '',
        moduleName: data?.moduleName || '',
        extraParams: data?.extraParams
          ? Object.keys(data.extraParams).map((k) => {
              return {
                key: k,
                value: data.extraParams[k],
              };
            })
          : [{ key: '', value: '' }],
      };

      setSelectedProvider(tempFormData.provider);
      setFormData(tempFormData);
      setInitialFormData(tempFormData);
    }
  }, [data]);

  const closeAlert = () => {
    const tempAlert = { ...showAlert };
    Object.keys(tempAlert).forEach((a) => (tempAlert[a] = false));
    setShowAlert(tempAlert);
    setErrorMessage('');
  };

  useEffect(() => {
    const tempAlert = { ...showAlert };
    if (isCreateError || isUpdateError) {
      const tempErrorMessage =
        createError?.data?.message ||
        updateError?.data?.message ||
        t(`${translationJSONPrefix}.form.errorAlertLabel`);
      setErrorMessage(tempErrorMessage);
      setTimeout(() => {
        tempAlert.formUpdateError = true;
        tempAlert.formUpdateSuccess = false;
        setShowAlert(tempAlert);
      }, 500);
    }
  }, [isCreateError, isUpdateError]);

  useEffect(() => {
    const tempAlert = { ...showAlert };
    if (isCreateSuccess || isUpdateSuccess) {
      tempAlert.formUpdateSuccess = true;
      tempAlert.formUpdateError = false;
      setShowAlert(tempAlert);
      getLanguageModel({ id: createdData?.id || updatedData?.id });
    }
  }, [isCreateSuccess, isUpdateSuccess]);

  useEffect(() => {
    if (showAlert.formUpdateError || showAlert.formUpdateSuccess)
      setTimeout(closeAlert, 5000);
  }, [showAlert.formUpdateError, showAlert.formUpdateSuccess]);

  return (
    <PageContainer drawer={SECTIONS.APP_CONFIGURATION}>
      <AddPageHeader
        routes={[
          {
            title: t(`${translationJSONPrefix}.title`),
            endpoint: mainPageRoute,
          },
        ]}
        isEdit={isEdit ? true : false}
      />

      <FormContainer>
        <AlertStyled
          onClose={closeAlert}
          severity={showAlert.formUpdateError ? 'error' : 'success'}
          style={{
            marginTop:
              showAlert.formUpdateError || showAlert.formUpdateSuccess
                ? 10
                : -60,
          }}
        >
          {showAlert.formUpdateSuccess &&
            (isEdit
              ? t(`${translationJSONPrefix}.form.updatedAlertLabel`)
              : t(`${translationJSONPrefix}.form.createdAlertLabel`))}
          {showAlert.formUpdateError &&
            (errorMessage ||
              t(`${translationJSONPrefix}.form.errorAlertLabel`))}
        </AlertStyled>

        {isLoading ? (
          <Loader label={t(`${translationJSONPrefix}.form.loadingLabel`)} />
        ) : (
          <AppForm
            initialValues={formData}
            validationSchema={formSchema(t, selectedProvider)}
            onSubmit={(values, { setSubmitting }) => {
              const valuesToSend = isEdit
                ? getChangedValues(values, initialFormData)
                : { ...values };

              valuesToSend.capabilities = {
                streaming: valuesToSend?.streaming || false,
                conversation: valuesToSend?.conversation || false,
              };

              if (valuesToSend.provider === PROVIDERS.CUSTOM.value) {
                const tempParams = {};
                valuesToSend?.extraParams?.forEach((ep) => {
                  tempParams[ep.key] = ep.value;
                });

                valuesToSend.extraParams = tempParams;
              } else delete valuesToSend.extraParams;

              delete valuesToSend.conversation;
              delete valuesToSend.streaming;

              if (isEdit)
                updateLanguageModel({
                  id: isEdit,
                  update: valuesToSend,
                });
              else
                createLanguageModel({
                  body: valuesToSend,
                });

              setSubmitting(false);
            }}
            formRenderFunction={(formikState) =>
              formRenderFunction(
                formikState,
                isCreateLoading || isUpdateLoading,
                isEdit,
                setSelectedProvider,
              )
            }
          />
        )}
      </FormContainer>
    </PageContainer>
  );
};

export default LanguageModelAddPage;
