/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import {
  API_V1_PATH,
  SPECIPHIC_ASK_API_URL,
} from '../../../constants/apiPaths';

const fetchCompanyLogoSvg = async (url, auth) => {
  try {
    const baseUrl = `${SPECIPHIC_ASK_API_URL}/${API_V1_PATH}`;
    const response = await fetch(`${baseUrl}${url}`, {
      headers: {
        Authorization: `Bearer ${auth.user?.access_token}`,
      },
    });

    // if (!response.ok) {
    //   throw new Error('Failed to fetch SVG');
    // }

    const svgBlob = await response.blob();
    return URL.createObjectURL(svgBlob);
  } catch (error) {
    // console.error('Error fetching SVG:', error);
    return '';
  }
};

export default fetchCompanyLogoSvg;
