/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { Box, DialogTitle, styled } from '@mui/material';

export const HighlighedTextWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 5,
  margin: '10px 0',
}));

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: 18,
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
}));

export const TitleIconWrapper = styled(`span`)({
  display: 'flex',
  alignItems: 'center',
});
