/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React, { useEffect, useState } from 'react';
import { FormControl, MenuItem } from '@mui/material';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { StyledSelect } from './miNavigationDropdown.styled';
import * as PATHS from '../../../constants/path';

const MiNavigationDropdown = () => {
  const navigate = useNavigate();
  const { featureName } = useParams();

  const location = useLocation();
  const [selectedPath, setSelectedPath] = useState(location.pathname);

  const basePaths = {
    [`${PATHS.MI_REPORTS}/${featureName}`]: 'Reports',
    [`${PATHS.MI_REPORT_SOURCES}/${featureName}`]: 'Report Sources',
    [`${PATHS.MI_REPORT_TOPICS}/${featureName}`]: 'Report Topics',
    [PATHS.MI_CONFIGURATION]: 'Configuration',
  };

  const handleChange = (event) => {
    const newPath = event.target.value;
    setSelectedPath(newPath);
    navigate(newPath);
  };

  useEffect(() => {
    const matchedPath = Object.keys(basePaths).find((path) =>
      location.pathname.includes(path),
    );
    if (matchedPath) {
      setSelectedPath(matchedPath);
    }
  }, [location.pathname]);

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 120, mt: 3 }}>
        <StyledSelect
          value={selectedPath}
          onChange={handleChange}
          size="small"
          variant="outlined"
          inputProps={{ 'aria-label': 'Navigation' }}
        >
          {Object.entries(basePaths).map(([path, label]) => (
            <MenuItem key={path} value={path}>
              {label}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </div>
  );
};

export default MiNavigationDropdown;
