/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

export const EXTERNAL_PREPROCESSOR_API_ENDPOINT = 'preprocessors';
export const EXTERNAL_EMBEDDINGS_API_ENDPOINT = 'embeddings';
