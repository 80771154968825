/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

const addDataSourceEPFactory = (builder) =>
  builder.mutation({
    query: (data) => ({
      url: `data-sources`,
      method: 'POST',
      body: data,
      'Content-Type': 'application/json',
    }),
    invalidatesTags: ['data-sources'],
  });

const addFilesToDataSourceEPFactory = (builder) =>
  builder.mutation({
    query: ({ dataSourceId, files }) => {
      const formData = new FormData();
      files?.forEach((file) => {
        formData.append('files', file);
      });
      return {
        url: `/data-sources/${dataSourceId}/files`,
        method: 'POST',
        body: formData,
      };
    },
    invalidatesTags: ['data-source', 'data-sources'],
  });

const deleteDataSourceByIdEPFactory = (builder) =>
  builder.mutation({
    query: ({ dataSourceId, isDeleteContainer }) => ({
      url: `data-sources/${dataSourceId}${
        isDeleteContainer ? `?isDeleteContainer=${isDeleteContainer}` : ``
      }`,
      method: 'DELETE',
    }),
    invalidatesTags: ['data-sources'],
  });

const getDataSourceEPFactory = (builder) =>
  builder.query({
    query: ({ storageId = '', pageNo, pageSize, query, used }) => {
      const baseUrl = `data-sources${storageId ? `/${storageId}` : ''}`;

      // Build query parameters in one step, only appending defined values
      const queryParams = new URLSearchParams({
        ...(pageNo && { pageNo }),
        ...(pageSize && { pageSize }),
        ...(query && { query }),
        ...(typeof used === 'boolean' && { used }),
      });

      return {
        url: `${baseUrl}?${queryParams}`,
        method: 'GET',
      };
    },
    providesTags: ['data-sources', 'data-source'],
  });

const deleteFilesFromDataSourceEPFactory = (builder) =>
  builder.mutation({
    query: ({ dataSourceId, fileNames }) => ({
      url: `data-sources/${dataSourceId}/files`,
      method: 'DELETE',
      body: {
        fileNames: fileNames,
      },
    }),
    invalidatesTags: ['data-sources', 'data-source'],
  });

const getDataSourceFilesEPFactory = (builder) =>
  builder.query({
    query: ({ dataSourceId, pageNumber, pageSize, query }) => {
      const baseUrl = `data-sources/${dataSourceId}/files`;

      // Construct query parameters
      const queryParams = new URLSearchParams();
      if (pageNumber) queryParams.append('page-number', pageNumber);
      if (pageSize) queryParams.append('page-size', pageSize);
      if (query) queryParams.append('query', query);

      // Construct full URL with query parameters
      const url = `${baseUrl}?${queryParams.toString()}`;

      return {
        url,
        method: 'GET',
      };
    },
    providesTags: ['data-source'],
    invalidateTags: ['data-sources'],
  });

export {
  addDataSourceEPFactory,
  addFilesToDataSourceEPFactory,
  deleteDataSourceByIdEPFactory,
  getDataSourceEPFactory,
  deleteFilesFromDataSourceEPFactory,
  getDataSourceFilesEPFactory,
};
