/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';

const ReportSelect = ({
  handleSelectChange,
  myPreferences,
  reportIdentifier,
}) => {
  return (
    <Select
      value={
        myPreferences?.find(
          (pref) =>
            pref?.symbol === reportIdentifier || pref?.key === reportIdentifier,
        ) || ''
      }
      onChange={(e) => handleSelectChange(e.target.value)}
      fullWidth
    >
      {myPreferences?.map((preference, idx) => (
        <MenuItem
          key={idx}
          value={preference}
          onChange={(pref) => handleSelectChange(pref)}
        >
          {preference.symbol || preference.key}
        </MenuItem>
      ))}
    </Select>
  );
};

ReportSelect.propTypes = {
  handleSelectChange: PropTypes.func,
  myPreferences: PropTypes.array,
  reportIdentifier: PropTypes.string,
};

export default ReportSelect;
