/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Tooltip,
  Icon,
  ButtonGroup,
  Stack,
  Typography,
  Grid,
  IconButton,
  TextField,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  DeleteForever,
  FileOpen,
  KeyboardArrowRight,
  KeyboardArrowLeft,
  KeyboardReturn,
  Clear,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import DropdownOne from '../../../../../components/dropdownone';
import CustomToolbar from './fileColletionDataGridCustomToolbar.component';
import {
  StyledDataGrid,
  StyledSearchIcon,
  StyledClearIcon,
  StyledFileWrapper,
  TagsChip,
  PaginationTextTypography,
  ProgressBar,
  LoadingProgress,
} from './fileCollectionsDataGrid.styled';
import { ReactComponent as FileCollectionIcon } from '../../../../../assets/file-collection-icon.svg';
import { useDeleteCollectionsMutation } from '../../../../../redux/services/speciphicAsk';
import { useDispatch } from 'react-redux';
import { pushToast } from '../../../../../redux/reducers/toasts.slice';
import * as PATHS from '../../../../../constants/path';
import ConfirmationDialog from '../../../../../components/confirmationDialog';
import DisplayTime from '../../../../../components/timezone.component';
import { sortByDateField } from '../../../../../utils/functions';
import { RowPerPageOptions } from '../../../../../constants/rowPerPageOptions';

const FileCollectionDataGrid = ({
  fileCollections,
  customPageNo,
  customPageSize,
  onCustomPageChange,
  onCustomPageSizeChange,
  onSearchChange,
  isNextPageLoading,
  total,
  handleInputChange,
  setSearchQuery,
  isSearching,
  searchQuery,
}) => {
  const [selectedCollection, setSelectedCollection] = useState({
    id: '',
    name: '',
  });
  const [openDeleteCollection, setOpenDeleteCollection] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState('');

  const [
    deleteCollection,
    {
      isError: isDeleteFileCollectionsError,
      isLoading: isDeleteFileCollectionsLoading,
      isSuccess: isDeleteFileCollectionsSuccess,
    },
  ] = useDeleteCollectionsMutation();

  const handleDeleteCollection = (fileCollectionsIdDelete) => {
    setOpenDeleteCollection(false);
    deleteCollection({
      collectionId: fileCollectionsIdDelete,
    });
  };

  const openDeleteFileDialog = (e, row) => {
    e.stopPropagation();
    setOpenDeleteCollection(true);
    setSelectedCollection({
      name: row.name,
      id: row.id,
    });
  };

  const handleCancelDeleteClick = () => {
    setOpenDeleteCollection(false);
  };

  useEffect(() => {
    if (isDeleteFileCollectionsError) {
      dispatch(
        pushToast({
          message: t('fileCollectionsPage.alertMessages.deleteFileAlert'),
          severity: 'success',
        }),
      );
    }
  }, [isDeleteFileCollectionsError]);

  // const getDates = (props) => {
  //   return new Date(props.row.createdDate).toLocaleDateString(
  //     'en-US',
  //     DATE_OPTIONS,
  //   );
  // };
  const handleRowClick = (row) => {
    navigate(`${PATHS.FILE_COLLECTIONS}/${row.id}`, {
      state: row,
    });
  };

  const fileCollectionNameColumn = (props) => {
    return (
      <StyledFileWrapper>
        <Icon component={FileCollectionIcon} sx={{ marginRight: 1 }} />
        {props.row?.name}
        <br />
        {props.row?.description}
      </StyledFileWrapper>
    );
  };

  const fileCollectionLlmColumn = (props) => {
    return (
      <StyledFileWrapper>
        {props.row?.languageModelName ||
          t('fileCollectionsPage.dataGrid.notFound')}
      </StyledFileWrapper>
    );
  };

  const fileCollectionTagsColumn = (props) => {
    const { tags } = props.row;
    return (
      <StyledFileWrapper>
        {tags && tags?.length > 0 ? (
          <div>
            <TagsChip label={tags[0]} />
            {tags.length > 1 && (
              <Tooltip title={tags.join(', ')}>
                <TagsChip label={`+ ${tags.length - 1}`} />
              </Tooltip>
            )}
          </div>
        ) : (
          t('fileCollectionsPage.dataGrid.noTags')
        )}
      </StyledFileWrapper>
    );
  };

  const fileCollectionPreProColumn = (props) => {
    const { externalPreprocessor: prePro } = props.row;

    return (
      <StyledFileWrapper>
        {prePro && prePro?.length > 0 ? (
          <div>
            <TagsChip label={prePro[0]?.name} />
            {prePro.length > 1 && (
              <Tooltip title={prePro.map((p) => p?.name).join(', ')}>
                <TagsChip label={`+ ${prePro.length - 1}`} />
              </Tooltip>
            )}
          </div>
        ) : (
          'default'
        )}
      </StyledFileWrapper>
    );
  };

  const actionColumn = (props) => {
    return (
      <>
        <ButtonGroup>
          <Tooltip title={t('fileCollectionsPage.columnAction.openFile')} arrow>
            <LoadingButton
              variant="text"
              onClick={() => handleRowClick(props.row)}
            >
              <span>
                <FileOpen />
              </span>
            </LoadingButton>
          </Tooltip>
          <Tooltip
            title={t('fileCollectionsPage.columnAction.deleteFile')}
            arrow
          >
            <LoadingButton
              variant="text"
              onClick={(e) => openDeleteFileDialog(e, props.row)}
              loading={
                props.row.id == selectedCollection.id &&
                isDeleteFileCollectionsLoading
              }
            >
              <span>
                <DeleteForever />
              </span>
            </LoadingButton>
          </Tooltip>
        </ButtonGroup>
      </>
    );
  };

  const CustomPagination = () => (
    <Stack direction="row" spacing={3} alignItems="center">
      <Grid container alignItems="center" spacing={1} sx={{ pb: 1 }}>
        <Grid item>
          <Typography variant="body1">
            {' '}
            {t(`fileCollectionsPage.dataGrid.columns.rows`)}
          </Typography>
        </Grid>
        <Grid item alignItems="center">
          <DropdownOne
            value={customPageSize}
            onChange={(value) => onCustomPageSizeChange(value)}
            label=""
            items={RowPerPageOptions}
          />
        </Grid>
      </Grid>

      <Stack direction="row" alignItems="center">
        <IconButton
          onClick={() => onCustomPageChange(customPageNo - 1, inputValue)}
          disabled={customPageNo === 0}
        >
          <KeyboardArrowLeft />
        </IconButton>

        <Stack
          display="flex"
          justifyContent="space-around"
          alignItems="center"
          flexDirection="row"
        >
          <PaginationTextTypography>
            {customPageNo * customPageSize + 1}{' '}
          </PaginationTextTypography>
          <PaginationTextTypography> - </PaginationTextTypography>
          <PaginationTextTypography>
            {Math.min((customPageNo + 1) * customPageSize, total)}
          </PaginationTextTypography>
          <PaginationTextTypography>
            {t(`fileCollectionsPage.dataGrid.columns.of`)}
          </PaginationTextTypography>
          <PaginationTextTypography>{total}</PaginationTextTypography>
        </Stack>

        <IconButton
          onClick={() => onCustomPageChange(customPageNo + 1, inputValue)}
          disabled={(customPageNo + 1) * customPageSize >= total}
        >
          <KeyboardArrowRight />
        </IconButton>
      </Stack>
    </Stack>
  );

  const columnsForDesktop = [
    {
      field: 'name',
      headerName: t('fileCollectionsPage.dataGrid.columns.fileCollectionName'),
      width: 250,
      renderCell: fileCollectionNameColumn,
      sortable: false,
    },
    {
      field: 'ingestedFilesCount',
      headerName: t('fileCollectionsPage.dataGrid.columns.ingestedFilesCount'),
      width: 120,
      sortable: false,
    },
    {
      field: 'languageModelId',
      headerName: t('fileCollectionsPage.dataGrid.columns.llm'),
      width: 250,
      renderCell: fileCollectionLlmColumn,
      sortable: false,
    },
    {
      field: 'tags',
      headerName: t('fileCollectionsPage.dataGrid.columns.tags'),
      minWidth: 250,
      flex: 1,
      sortable: false,
      renderCell: fileCollectionTagsColumn,
    },
    {
      field: 'prePro',
      headerName: t('fileCollectionsPage.dataGrid.columns.prePro'),
      minWidth: 250,
      flex: 1,
      sortable: false,
      renderCell: fileCollectionPreProColumn,
    },
    {
      field: 'lastUpdatedBy',
      headerName: t('fileCollectionsPage.dataGrid.columns.createdBy'),
      width: 150,
      sortable: false,
    },
    {
      field: 'lastUpdatedDate',
      headerName: t('fileCollectionsPage.dataGrid.columns.createdOn'),
      width: 200,
      renderCell: (params) => <DisplayTime time={params.row.lastUpdatedDate} />,
      sortable: false,
    },

    {
      field: 'actions',
      headerName: t('fileCollectionsPage.dataGrid.columns.actions'),
      width: 120,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: actionColumn,
    },
  ];

  return (
    <>
      <Box>
        <TextField
          id="input-with-icon-textfield"
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <StyledSearchIcon />
              </InputAdornment>
            ),
            endAdornment: searchQuery && (
              <InputAdornment position="end">
                {isSearching ? (
                  <CircularProgress size={18} />
                ) : (
                  <IconButton onClick={() => setSearchQuery('')}>
                    <Clear />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
          value={searchQuery}
          variant="standard"
          onChange={handleInputChange}
        />
      </Box>
      <StyledDataGrid
        autoHeight
        pageSize={50}
        rows={sortByDateField([...fileCollections], 'lastUpdatedDate')}
        columns={columnsForDesktop}
        disableSelectionOnClick
        disableColumnMenu
        onRowClick={
          isNextPageLoading ? null : (props) => handleRowClick(props.row)
        }
        loading={isNextPageLoading}
        components={{
          Pagination: CustomPagination,
        }}
      />
      <ConfirmationDialog
        open={openDeleteCollection}
        title={t('fileCollectionsPage.labels.deleteTitle')}
        messageLine1={t('fileCollectionsPage.alertMessages.deleteAlertTitle', {
          name: selectedCollection.name,
        })}
        messageLine2={t('fileCollectionsPage.alertMessages.deleteMessage1')}
        alertSeverity={t('fileCollectionsPage.alertMessages.warning')}
        yesButtonLabel={t('fileCollectionsPage.buttonText.delete')}
        noButtonLabel={t('fileCollectionsPage.buttonText.cancel')}
        onYesClick={() => handleDeleteCollection(selectedCollection.id)}
        onNoClick={handleCancelDeleteClick}
      />
    </>
  );
};

FileCollectionDataGrid.propTypes = {
  row: PropTypes.object,
  fileCollections: PropTypes.array,
  customPageNo: PropTypes.number,
  customPageSize: PropTypes.string,
  total: PropTypes.number,
  onCustomPageChange: PropTypes.func,
  onCustomPageSizeChange: PropTypes.func,
  isNextPageLoading: PropTypes.bool,
  onSearchChange: PropTypes.func,
  isSearchLoading: PropTypes.bool,
  handleInputChange: PropTypes.func,
  searchQuery: PropTypes.string,
  setSearchQuery: PropTypes.func,
  isSearching: PropTypes.bool,
};

export default FileCollectionDataGrid;
