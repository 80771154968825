const {
  REACT_APP_AUTH_URL,
  REACT_APP_AUTH_AUTHORITY,
  REACT_APP_AUTH_REALM,
  REACT_APP_AUTH_CLIENT,
  REACT_APP_AUTH_SCOPE,
  REACT_APP_AUTH_REDIRECT_URL,
  REACT_APP_AUTH_LOGOUT_REDIRECT_URL,
  REACT_APP_USER_IDENTIFIER,
  REACT_APP_AUTH_PROVIDER,
} = process.env;

export const AUTH_URL = REACT_APP_AUTH_URL;
export const REALM = REACT_APP_AUTH_REALM;
export const AUTHORITY = REACT_APP_AUTH_AUTHORITY;
export const CLIENT = REACT_APP_AUTH_CLIENT;
export const REDIRECT_URL = REACT_APP_AUTH_REDIRECT_URL;
export const LOGOUT_REDIRECT_URL = REACT_APP_AUTH_LOGOUT_REDIRECT_URL;
export const SCOPE = REACT_APP_AUTH_SCOPE;
export const AUTH_PROVIDER = REACT_APP_AUTH_PROVIDER;
export const USERIDENTIFIER = REACT_APP_USER_IDENTIFIER;
