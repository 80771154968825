/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import {
  ListItem,
  List,
  ListItemAvatar,
  ListItemText,
  Typography,
  Box,
  Chip,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import MiMarkdownChip from '../../../../../components/marketIntelligence/miMarkdownChip';
import AskBotIcon from '../../../../../assets/askBotIcon';

const MiConversationList = ({ conversationHistory, isConversationLoading }) => {
  const { t } = useTranslation();
  const conversationListRef = useRef(null);

  const [showCursor, setShowCursor] = useState(true);

  useEffect(() => {
    if (isConversationLoading) {
      const interval = setInterval(() => {
        setShowCursor((prev) => !prev);
      }, 500);
      return () => clearInterval(interval);
    }
  }, [isConversationLoading]);

  useEffect(() => {
    if (conversationListRef.current) {
      conversationListRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [conversationHistory]);

  const sortedConversationHistory = [...conversationHistory]?.sort((a, b) => {
    const dateA = new Date(a.timestamp);
    const dateB = new Date(b.timestamp);
    return dateA - dateB;
  });

  return (
    <>
      {sortedConversationHistory?.map((chat, index) => (
        <List
          key={index}
          sx={{
            width: '100%',
          }}
        >
          {/* question */}
          <ListItem
            sx={{ alignItems: 'flex-start' }}
            ref={conversationListRef}
            key={index + `user`}
          >
            <ListItemAvatar ref={conversationListRef}>
              <Typography>You</Typography>
            </ListItemAvatar>
            <ListItemText
              ref={conversationListRef}
              primary={
                <Box>
                  {/* <Typography fontSize="10px">
                        {message.time} - {message.timestamp}
                      </Typography> */}

                  <Chip
                    label={chat.query}
                    sx={{
                      backgroundColor: 'primary.light',
                      borderRadius: 2,
                    }}
                  />
                </Box>
              }
            />
          </ListItem>

          {(chat?.hasError || (chat?.results && chat.results.answer)) && (
            <ListItem
              ref={conversationListRef}
              sx={{ alignItems: 'flex-start' }}
              key={index + `bot`}
            >
              <ListItemAvatar ref={conversationListRef}>
                <AskBotIcon />
              </ListItemAvatar>
              <ListItemText
                ref={conversationListRef}
                primary={
                  <Box>
                    {/* <Typography fontSize="10px">
                        {message.time} - {message.timestamp}
                      </Typography> */}

                    <MiMarkdownChip
                      markdown={
                        chat?.hasError
                          ? chat.errorMessage
                          : chat.results?.answer
                      }
                    />
                  </Box>
                }
              />
            </ListItem>
          )}

          {index === conversationHistory.length - 1 &&
            isConversationLoading && (
              <ListItem ref={conversationListRef}>
                <ListItemAvatar ref={conversationListRef}>
                  <AskBotIcon />
                </ListItemAvatar>
                <div
                  style={{
                    lineHeight: 0,
                    fontSize: 'large',
                    fontWeight: 'bolder',
                  }}
                >
                  {showCursor && '|'}{' '}
                </div>
              </ListItem>
            )}
        </List>
      ))}
    </>
  );
};

MiConversationList.propTypes = {
  conversationHistory: PropTypes.array,
  isConversationLoading: PropTypes.bool,
};

export default MiConversationList;
