/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React, { useEffect, useState } from 'react';
import { array, bool, object, string } from 'yup';
import { useTranslation } from 'react-i18next';
import { Form } from 'formik';
import { Add, Delete } from '@mui/icons-material';
import { useSearchParams } from 'react-router-dom';
import { Button, Chip, IconButton, TextField } from '@mui/material';

import PageContainer from '../../../components/pageContainer';
import { SECTIONS } from '../../../constants/drawer';
import AddPageHeader from '../../../components/form/addPageHeader.component';
import { APP_CONFIGURATION } from '../../../constants/path';
import { EXTERNAL_CHUNKING_CONFIG } from '../../../constants/appConfigurationPaths';
import {
  FormContainer,
  InputsContainer,
  InputsWrapper,
  TitleWrapper2,
} from '../styled';
import AppForm, {
  AppAutocompleteField,
  AppSelectField,
  AppTextField,
  FormButtons,
} from '../../../components/form';
import { AlertStyled } from '../../settings/filesCollectionSettingsPage/styled';
import { getChangedValues } from '../../../components/form/functions';
import {
  useCreateExternalAPIMutation,
  useLazyGetOneExternalAPIQuery,
  useUpdateExternalAPIMutation,
} from '../../../redux/services/speciphicAsk';
import Loader from '../../../components/loader';
import { EXTERNAL_PREPROCESSOR_API_ENDPOINT } from '../../../constants/externalAPIEndpoints';
import { arrayToObject, objectToArray } from '../../../utils/functions';

const translationJSONPrefix = 'appConfigurationSection.externalChunking';
const mainPageRoute = `${APP_CONFIGURATION}${EXTERNAL_CHUNKING_CONFIG.endpoint}`;

const OAUTH2 = {
  label: 'OAuth 2.0',
  value: 'oauth2',
};
const CLIENT_CREDENTIALS = 'Client Credentials';

const ALLOWED_METHODS = ['GET', 'POST'];
const AUTH_TYPES = [OAUTH2];
const GRANT_TYPES = [CLIENT_CREDENTIALS];

const defaultDataState = {
  name: '',
  apiEndpoint: '',
  httpMethod: 'GET',
  authenticationCredentials: {
    authScheme: 'none',
    grantType: 'Client Credentials',
    tokenEndpoint: '',
    clientId: '',
    clientSecret: '',
    scope: '',
  },
  ext: [],
  headers: [],
  queryParams: [],
};

const formSchema = (t) => {
  const translationPrefix = (label) =>
    `${translationJSONPrefix}.form.fields.${label}.errorMessages`;

  return object({
    name: string()
      .required(t(`${translationPrefix('name')}.required`))
      .min(3, t(`${translationPrefix('name')}.min`))
      .max(250, t(`${translationPrefix('name')}.max`)),
    apiEndpoint: string()
      .required(t(`${translationPrefix('apiEndpoint')}.required`))
      .url(t(`${translationPrefix('apiEndpoint')}.url`)),
    httpMethod: string()
      .required(t(`${translationPrefix('httpMethod')}.required`))
      .oneOf(ALLOWED_METHODS, t(`${translationPrefix('httpMethod')}.method`)),
    authenticationCredentials: object(),
    ext: array()
      .of(string())
      .required(t(`${translationPrefix('ext')}.required`)),
    headers: array().of(
      object().shape({
        key: string(),
        value: string(),
      }),
    ),
    queryParams: array().of(
      object().shape({
        key: string(),
        value: string(),
      }),
    ),
  });
};

const formRenderFunction = (
  { values, errors, touched, dirty, isSubmitting, resetForm, setFieldValue },
  formSending,
  formikState,
) => {
  const { t } = useTranslation();
  const translationPrefix = `${translationJSONPrefix}.form.fields`;

  return (
    <Form>
      <InputsContainer>
        <AppSelectField
          name="httpMethod"
          values={values}
          label={t(`${translationPrefix}.httpMethod.label`)}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          menuData={ALLOWED_METHODS.map((m) => {
            return {
              label: m,
              value: m,
            };
          })}
        />
      </InputsContainer>

      <InputsContainer>
        <AppTextField
          style={{ width: '100%' }}
          name="name"
          label={t(`${translationPrefix}.name.label`)}
          placeholder={t(`${translationPrefix}.name.placeholder`)}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
        />
      </InputsContainer>

      <InputsContainer>
        <AppTextField
          style={{ width: '100%' }}
          name="apiEndpoint"
          label={t(`${translationPrefix}.apiEndpoint.label`)}
          placeholder={t(`${translationPrefix}.apiEndpoint.placeholder`)}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
        />
      </InputsContainer>

      <InputsContainer>
        <AppSelectField
          name="authenticationCredentials.authScheme"
          value={values.authenticationCredentials.authScheme}
          label={t(
            `${translationPrefix}.authenticationCredentials.authScheme.label`,
          )}
          errors={errors}
          touched={touched}
          onChange={(e) => {
            const tempAuthorization = {
              ...values.authenticationCredentials,
              authScheme: e.target.value,
            };
            setFieldValue('authenticationCredentials', tempAuthorization);
          }}
          menuData={[
            { label: 'None', value: 'none' },
            ...AUTH_TYPES.map((m) => ({
              label: m.label,
              value: m.value,
            })),
          ]}
        />
      </InputsContainer>
      {values.authenticationCredentials.authScheme !== 'none' && (
        <>
          <InputsContainer>
            <AppSelectField
              name="authenticationCredentials.grantType"
              value={values.authenticationCredentials.grantType}
              label={t(
                `${translationPrefix}.authenticationCredentials.grantType.label`,
              )}
              errors={errors}
              touched={touched}
              onChange={(e) => {
                const tempAuthorization = {
                  ...values.authenticationCredentials,
                  grantType: e.target.value,
                };
                setFieldValue('authenticationCredentials', tempAuthorization);
              }}
              menuData={GRANT_TYPES.map((m) => ({
                label: m,
                value: m,
              }))}
            />
          </InputsContainer>
          <InputsContainer>
            <AppTextField
              style={{ width: '100%' }}
              name="authenticationCredentials.tokenEndpoint"
              label={t(
                `${translationPrefix}.authenticationCredentials.tokenEndpoint.label`,
              )}
              placeholder={t(
                `${translationPrefix}.authenticationCredentials.tokenEndpoint.placeholder`,
              )}
              value={values.authenticationCredentials.tokenEndpoint}
              onChange={(e) => {
                const tempAuthorization = {
                  ...values.authenticationCredentials,
                  tokenEndpoint: e.target.value,
                };
                setFieldValue('authenticationCredentials', tempAuthorization);
              }}
              errors={errors}
              touched={touched}
            />
          </InputsContainer>
          <InputsContainer>
            <AppTextField
              style={{ width: '100%' }}
              name="authenticationCredentials.clientId"
              label={t(
                `${translationPrefix}.authenticationCredentials.clientId.label`,
              )}
              placeholder={t(
                `${translationPrefix}.authenticationCredentials.clientId.placeholder`,
              )}
              value={values.authenticationCredentials.clientId}
              onChange={(e) => {
                const tempAuthorization = {
                  ...values.authenticationCredentials,
                  clientId: e.target.value,
                };
                setFieldValue('authenticationCredentials', tempAuthorization);
              }}
              errors={errors}
              touched={touched}
            />
            <AppTextField
              style={{ width: '100%' }}
              name="authenticationCredentials.clientSecret"
              label={t(
                `${translationPrefix}.authenticationCredentials.clientSecret.label`,
              )}
              placeholder={t(
                `${translationPrefix}.authenticationCredentials.clientSecret.placeholder`,
              )}
              value={values.authenticationCredentials.clientSecret}
              onChange={(e) => {
                const tempAuthorization = {
                  ...values.authenticationCredentials,
                  clientSecret: e.target.value,
                };
                setFieldValue('authenticationCredentials', tempAuthorization);
              }}
              errors={errors}
              touched={touched}
            />
          </InputsContainer>
          <InputsContainer>
            <AppTextField
              style={{ width: '100%' }}
              name="authenticationCredentials.scope"
              label={t(
                `${translationPrefix}.authenticationCredentials.scope.label`,
              )}
              placeholder={t(
                `${translationPrefix}.authenticationCredentials.scope.placeholder`,
              )}
              value={values.authenticationCredentials.scope}
              onChange={(e) => {
                const tempAuthorization = {
                  ...values.authenticationCredentials,
                  scope: e.target.value,
                };
                setFieldValue('authenticationCredentials', tempAuthorization);
              }}
              errors={errors}
              touched={touched}
            />
          </InputsContainer>
        </>
      )}
      <InputsContainer>
        <AppAutocompleteField
          style={{ width: '100%' }}
          name="ext"
          label={t(`${translationPrefix}.ext.label`)}
          placeholder={t(`${translationPrefix}.ext.placeholder`)}
          values={values}
          setFieldValue={setFieldValue}
          options={[]}
          errors={errors}
          touched={touched}
        />
      </InputsContainer>
      <>
        <TitleWrapper2>
          {t(`${translationPrefix}.headers.fieldName`)}
        </TitleWrapper2>
        {Array.isArray(values.headers) &&
          values.headers.map((hd, i) => (
            <InputsContainer key={i}>
              <AppTextField
                style={{ width: '100%' }}
                name={`headers`}
                label={t(`${translationPrefix}.headers.label.key.label`)}
                placeholder={t(
                  `${translationPrefix}.headers.label.key.placeholder`,
                )}
                value={values['headers'][i].key}
                onChange={(e) => {
                  const tempValues = [...values['headers']];
                  tempValues[i].key = e.target.value;
                  setFieldValue('headers', tempValues);
                }}
              />
              <AppTextField
                style={{ width: '100%' }}
                name={'headers'}
                label={t(`${translationPrefix}.headers.label.value.label`)}
                placeholder={t(
                  `${translationPrefix}.headers.label.value.placeholder`,
                )}
                value={values['headers'][i].value}
                onChange={(e) => {
                  const tempValues = [...values['headers']];
                  tempValues[i].value = e.target.value;
                  setFieldValue('headers', tempValues);
                }}
              />
              <IconButton
                onClick={() => {
                  const tempParams = [...values['headers']];
                  tempParams.splice(i, 1);
                  setFieldValue('headers', tempParams);
                }}
              >
                <Delete />
              </IconButton>
            </InputsContainer>
          ))}

        <Button
          onClick={() => {
            setFieldValue('headers', [
              ...values['headers'],
              { key: '', value: '' },
            ]);
          }}
          variant="contained"
          startIcon={<Add />}
        >
          {t(`${translationPrefix}.headers.addButtonLabel`)}
        </Button>
      </>

      <InputsWrapper>
        <>
          <TitleWrapper2>
            {t(`${translationPrefix}.queryParams.fieldName`)}
          </TitleWrapper2>
          {values['queryParams'].map((hd, i) => (
            <InputsContainer key={i}>
              <AppTextField
                style={{ width: '100%' }}
                name={`queryParams`}
                label={t(`${translationPrefix}.queryParams.label.key.label`)}
                placeholder={t(
                  `${translationPrefix}.queryParams.label.key.placeholder`,
                )}
                value={values['queryParams'][i].key}
                onChange={(e) => {
                  const tempValues = [...values['queryParams']];
                  tempValues[i].key = e.target.value;
                  setFieldValue('queryParams', tempValues);
                }}
              />

              <AppTextField
                style={{ width: '100%' }}
                name={'queryParams'}
                label={t(`${translationPrefix}.queryParams.label.value.label`)}
                placeholder={t(
                  `${translationPrefix}.queryParams.label.value.placeholder`,
                )}
                value={values['queryParams'][i].value}
                onChange={(e) => {
                  const tempValues = [...values['queryParams']];
                  tempValues[i].value = e.target.value;
                  setFieldValue('queryParams', tempValues);
                }}
              />

              <IconButton
                onClick={() => {
                  const tempParams = [...values['queryParams']];
                  tempParams.splice(i, 1);

                  setFieldValue('queryParams', tempParams);
                }}
              >
                <Delete />
              </IconButton>
            </InputsContainer>
          ))}

          <Button
            onClick={() => {
              setFieldValue('queryParams', [
                ...values['queryParams'],
                { key: '', value: '' },
              ]);
            }}
            variant="contained"
            startIcon={<Add />}
          >
            {t(`${translationPrefix}.queryParams.addButtonLabel`)}
          </Button>
        </>
      </InputsWrapper>

      <FormButtons
        resetForm={resetForm}
        dirty={dirty}
        isSubmitting={isSubmitting || formSending}
      />
    </Form>
  );
};

const ExternalChunkingAddPage = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const [initialFormData, setInitialFormData] = useState(defaultDataState);
  const [formData, setFormData] = useState(defaultDataState);
  const [isEdit, setIsEdit] = useState(false);
  const [showAlert, setShowAlert] = useState({
    formUpdateError: false,
    formUpdateSuccess: false,
  });
  const [errorMessage, setErrorMessage] = useState('');

  const [getOnePrePro, { data, error, isLoading, isSuccess }] =
    useLazyGetOneExternalAPIQuery();

  const [
    createPrePro,
    {
      data: createdData,
      error: createError,
      isLoading: isCreateLoading,
      isSuccess: isCreateSuccess,
      isError: isCreateError,
    },
  ] = useCreateExternalAPIMutation();

  const [
    updatePrePro,
    {
      data: updatedData,
      error: updateError,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
    },
  ] = useUpdateExternalAPIMutation();

  useEffect(() => {
    const preProId = searchParams.get('id');

    if (preProId) {
      setIsEdit(preProId);
      getOnePrePro({
        id: preProId,
        endpoint: EXTERNAL_PREPROCESSOR_API_ENDPOINT,
      });
    }
  }, []);

  useEffect(() => {
    if (isSuccess && data) {
      const dataDeepCopy = JSON.parse(JSON.stringify(data));

      const tempFormData = {
        name: dataDeepCopy?.name || '',
        apiEndpoint: dataDeepCopy?.apiEndpoint || '',
        httpMethod: dataDeepCopy?.httpMethod || 'GET',
        authenticationCredentials: {
          authScheme:
            dataDeepCopy?.authenticationCredentials?.authScheme || 'OAuth 2.0',
          grantType:
            dataDeepCopy?.authenticationCredentials?.grantType ||
            'Client Credentials',
          tokenEndpoint:
            dataDeepCopy?.authenticationCredentials?.tokenEndpoint || '',
          clientId: dataDeepCopy?.authenticationCredentials?.clientId || '',
          clientSecret:
            dataDeepCopy?.authenticationCredentials?.clientSecret || '',
          scope: dataDeepCopy?.authenticationCredentials?.scope || '',
        },
        ext: dataDeepCopy?.ext,
        headers: objectToArray(dataDeepCopy?.headers) || [
          { key: '', value: '' },
        ],
        queryParams: objectToArray(dataDeepCopy?.queryParams) || [
          { key: '', value: '' },
        ],
      };

      setFormData(tempFormData);
      setInitialFormData(tempFormData);
    }
  }, [data, isSuccess]);

  const closeAlert = () => {
    const tempAlert = { ...showAlert };
    Object.keys(tempAlert).forEach((a) => (tempAlert[a] = false));
    setShowAlert(tempAlert);
    setErrorMessage('');
  };

  useEffect(() => {
    const tempAlert = { ...showAlert };
    if (isCreateError || isUpdateError) {
      const tempErrorMessage =
        createError?.data?.message ||
        updateError?.data?.message ||
        t(`${translationJSONPrefix}.form.errorAlertLabel`);
      setErrorMessage(tempErrorMessage);
      setTimeout(() => {
        tempAlert.formUpdateError = true;
        tempAlert.formUpdateSuccess = false;
        setShowAlert(tempAlert);
      }, 500);
    }
  }, [isCreateError, isUpdateError]);

  useEffect(() => {
    const tempAlert = { ...showAlert };
    if (isCreateSuccess || isUpdateSuccess) {
      tempAlert.formUpdateSuccess = true;
      tempAlert.formUpdateError = false;
      setShowAlert(tempAlert);
      getOnePrePro({
        id: createdData?.id || updatedData?.id,
        endpoint: EXTERNAL_PREPROCESSOR_API_ENDPOINT,
      });
    }
  }, [isCreateSuccess, isUpdateSuccess]);

  useEffect(() => {
    if (showAlert.formUpdateError || showAlert.formUpdateSuccess)
      setTimeout(closeAlert, 5000);
  }, [showAlert.formUpdateError, showAlert.formUpdateSuccess]);

  return (
    <PageContainer drawer={SECTIONS.APP_CONFIGURATION}>
      <AddPageHeader
        routes={[
          {
            title: t(`${translationJSONPrefix}.title`),
            endpoint: mainPageRoute,
          },
        ]}
        isEdit={isEdit ? true : false}
      />

      <FormContainer>
        <AlertStyled
          onClose={closeAlert}
          severity={showAlert.formUpdateError ? 'error' : 'success'}
          style={{
            marginTop:
              showAlert.formUpdateError || showAlert.formUpdateSuccess
                ? 10
                : -60,
          }}
        >
          {showAlert.formUpdateSuccess &&
            (isEdit
              ? t(`${translationJSONPrefix}.form.updatedAlertLabel`)
              : t(`${translationJSONPrefix}.form.createdAlertLabel`))}
          {showAlert.formUpdateError &&
            (errorMessage ||
              t(`${translationJSONPrefix}.form.errorAlertLabel`))}
        </AlertStyled>
        {isLoading ? (
          <Loader label={t(`${translationJSONPrefix}.form.loadingLabel`)} />
        ) : (
          <AppForm
            initialValues={formData}
            validationSchema={formSchema(t)}
            onSubmit={(values, { setSubmitting }) => {
              const valuesToSend = isEdit
                ? getChangedValues(values, initialFormData)
                : { ...values };

              if (valuesToSend?.headers)
                valuesToSend.headers = arrayToObject(valuesToSend.headers);
              if (valuesToSend?.queryParams)
                valuesToSend.queryParams = arrayToObject(
                  valuesToSend.queryParams,
                );

              if (isEdit)
                updatePrePro({
                  id: isEdit,
                  update: valuesToSend,
                  endpoint: EXTERNAL_PREPROCESSOR_API_ENDPOINT,
                });
              else
                createPrePro({
                  body: valuesToSend,
                  endpoint: EXTERNAL_PREPROCESSOR_API_ENDPOINT,
                });

              setSubmitting(false);
            }}
            formRenderFunction={(formikState) =>
              formRenderFunction(
                formikState,
                isCreateLoading || isUpdateLoading,
                isEdit,
              )
            }
          />
        )}
      </FormContainer>
    </PageContainer>
  );
};

export default ExternalChunkingAddPage;
