import PropTypes from 'prop-types';
import DataGridTable from '../../components/datagrid/datagrid.component';
import readableDate from './readableDate';
import QnA from './qna.component';
import DisplayTime from '../../components/timezone.component';

const SearchHistoryDataGrid = ({ chatHistory, fileCollections }) => {
  const columns = [
    {
      field: 'timestamp',
      headerName: 'Date',
      renderCell: (params) => <DisplayTime time={params.row?.timestamp} />,
      width: 200,
      sortable: false,
    },
    {
      field: 'query',
      headerName: 'Query',
      renderCell: (params) => (
        <QnA
          query={params.row?.query}
          results={params.row?.results}
          errorMessage={params.row?.errorMessage}
        />
      ),
      minWidth: 600,
      sortable: false,
      flex: 1,
    },
    {
      field: 'hasError',
      headerName: 'Has Error',
      renderCell: (params) => (params.row?.hasError ? 'True' : 'False'),
      minWidth: 100,
      flex: 0.4,
    },
    {
      headerName: 'File Collection',
      renderCell: (params) => {
        const fileCollection = fileCollections.find(
          (fc) => fc?.id === params.row?.collectionId,
        );
        return fileCollection ? fileCollection.name : 'Not Found';
      },
      width: 200,
      sortable: false,
    },
  ];

  return (
    <DataGridTable
      column={columns}
      row={chatHistory}
      sortingOrder={'desc'}
      sortingField={'Date'}
      rowId="id"
      rowHeightAuto={true}
      searchable={true}
      isPaginationRequired={false}
      serverSideSearch={false}
    />
  );
};

export default SearchHistoryDataGrid;

SearchHistoryDataGrid.propTypes = {
  chatHistory: PropTypes.arrayOf(PropTypes.object).isRequired,
  fileCollections: PropTypes.arrayOf(PropTypes.object).isRequired,
};
