/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import PropTypes from 'prop-types';
import { StyledAvatar } from './companyAvatar.styled';
import { MARKET_INTELLIGENCE_UNLISTED_FEATURE } from '../../../../../constants/marketIntelligence';
import fetchCompanyLogoSvg from '../../../../../redux/services/speciphicAsk/miCompanyLogoSvg.api';

const CompanyAvatar = ({ url, feature }) => {
  const [src, setSrc] = useState('');
  const auth = useAuth();

  useEffect(() => {
    const getSvg = async () => {
      if (feature === MARKET_INTELLIGENCE_UNLISTED_FEATURE) {
        setSrc(url);
        return;
      }
      if (auth.isAuthenticated) {
        const svgUrl = await fetchCompanyLogoSvg(url, auth);
        setSrc(svgUrl);
      }
    };

    getSvg();
  }, [url, feature, auth.isAuthenticated]);

  return src?.length > 0 ? <StyledAvatar src={src} /> : <StyledAvatar />;
};

CompanyAvatar.propTypes = {
  url: PropTypes.string,
  feature: PropTypes.string,
};

export default CompanyAvatar;
