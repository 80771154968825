/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import {
  Box,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  Paper,
  Select,
  styled,
} from '@mui/material';

export const ShadowBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  paddingTop: 0,
  width: '100%',
}));

export const TitleGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginX: theme.spacing(1),
}));

export const FormGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1),
}));

export const ConfigPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.spacing(1),
}));

export const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: theme.palette.background.light,
  borderRadius: theme.spacing(1),
}));

export const MenuBox = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const TagsChip = styled(Chip)({
  '.MuiChip-icon': {
    height: '18px',
  },
  minWidth: '110px',
  width: 'fit-content',
  height: '25px',
});

export const LoadingIndicator = styled(CircularProgress)({
  width: '20px !important',
  height: '20px !important',
  position: 'absolute',
  right: '30px',
  bottom: '10px',
});

export const StyledFormControl = styled(FormControl)({
  position: 'relative',
});
