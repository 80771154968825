/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { useEffect, useState } from 'react';
import {
  FormControlLabel,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  ArchiveBox,
  ParentLayoutBox,
  StyledFormControl,
  StyledSwitch,
} from './noReportLayout.styled';
import DisplayTime from '../../../../../components/timezone.component';
import { sortByDateField } from '../../../../../utils/functions';
import StatusAlert from '../statusAlert';

const translationJSONPrefix = 'newMarketIntelligencePage';

const NoReportLayout = ({
  status,
  stockKey,
  showArchives,
  handleShowArchives,
  allFinancialReports,
  reportData,
  handleReportChange,
  handleGenerateReport,
  isGenerateReportLoading,
  isGetAllFinancialReportsFetching,
}) => {
  const { t } = useTranslation();

  const [archiveData, setArchiveData] = useState({});
  const [latestReport, setLatestReport] = useState({});

  useEffect(() => {
    if (reportData) {
      const archive = allFinancialReports
        ?.filter((report) => report.status !== 'ERROR')
        ?.find(
          (report) => report?.lastUpdatedDate === reportData.lastUpdatedDate,
        );
      setArchiveData(archive || {});
    }

    if (allFinancialReports?.length > 0) {
      setLatestReport(
        sortByDateField([...allFinancialReports], 'lastUpdatedDate')?.[0],
      );
    }
  }, [reportData, allFinancialReports]);

  const handleChange = (event) => {
    const selectedDate = event.target.value;
    const selectedArchive = allFinancialReports.find(
      (report) => report.createdDate === selectedDate,
    );
    setArchiveData(selectedArchive);
    handleReportChange(selectedArchive);
  };

  if (isGetAllFinancialReportsFetching) {
    return (
      <ParentLayoutBox>
        <LinearProgress />
      </ParentLayoutBox>
    );
  }
  const isLatestReport =
    reportData?.reportId === null ||
    latestReport?.id === archiveData?.id ||
    latestReport?.id === reportData?.id ||
    reportData?.reportId?.length === 0;

  return (
    <ParentLayoutBox>
      {allFinancialReports?.filter((report) => report.status !== 'ERROR')
        ?.length > 0 && (
        <ArchiveBox noValidate component="form">
          <FormControlLabel
            control={
              <StyledSwitch
                value={showArchives}
                checked={showArchives}
                onChange={handleShowArchives}
              />
            }
            label={t(`${translationJSONPrefix}.reports.switch.archives`)}
          />
          {showArchives && (
            <StyledFormControl>
              <InputLabel>Archives</InputLabel>
              <Select
                onChange={handleChange}
                size="small"
                label="Archives"
                value={archiveData?.createdDate || ''}
              >
                {sortByDateField([...allFinancialReports], 'createdDate')
                  ?.filter((report) => report.status !== 'ERROR')
                  .map((report, index) => (
                    <MenuItem
                      key={index}
                      value={report?.createdDate}
                      disabled={reportData?.createdDate === report?.createdDate}
                    >
                      <Stack>
                        <Typography>{report?.key}</Typography>
                        <DisplayTime
                          time={report?.createdDate}
                          format="ddd, D MMM YYYY HH:mm"
                          isUtc={true}
                        />
                      </Stack>
                    </MenuItem>
                  ))}
              </Select>
            </StyledFormControl>
          )}
        </ArchiveBox>
      )}
      {/* <Typography sx={{ marginY: 2 }} variant="h4">
        {stockKey}
      </Typography> */}

      <StatusAlert
        isLatestReport={isLatestReport}
        reportData={reportData}
        status={status}
        handleGenerateReport={handleGenerateReport}
        isGenerateReportLoading={isGenerateReportLoading}
      />
    </ParentLayoutBox>
  );
};

NoReportLayout.propTypes = {
  status: PropTypes.string,
  stockKey: PropTypes.string,
  allFinancialReports: PropTypes.array,
  showArchives: PropTypes.bool,
  handleShowArchives: PropTypes.func,
  handleGenerateReport: PropTypes.func,
  handleReportChange: PropTypes.func,
  reportData: PropTypes.object,
  isGenerateReportLoading: PropTypes.bool,
  isGetAllFinancialReportsFetching: PropTypes.bool,
};

export default NoReportLayout;
